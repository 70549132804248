import React, { useEffect, useState, useRef, useContext } from 'react';
import Loader from 'react-dots-loader';

import { Utils, Subtitle, Checkbox, Button } from '../../../Components/Components';
import FinanceTable from '../../../Components/Table/FinanceTable';

import { UserContext } from '../../../Components/Contexts';

import { ReactComponent as BoxIcon } from '../../../Components/images/icons/box.svg';
import { ReactComponent as ShippingIcon } from '../../../Components/images/icons/shipping.svg';
import { ReactComponent as FolderIcon } from '../../../Components/images/icons/folder.svg';
import { ReactComponent as DownloadIcon } from '../../../Components/images/icons/cloud-download.svg';

import 'react-dots-loader/index.css';
import './Financial.scss';

const Financial = () => {

  const { account, isLogedIn } = useContext(UserContext);
  const ovTable = useRef();
  

  const [formItem, setFormItem] = useState({});
  const [formDisplay, setFormDisplay] = useState('shipping');
  const [tableStartPage, setTableStartPage] = useState(1);

  const [overviewLoading, setOverviewLoading] = useState(true); //loader da pagina

  const [fullTableData, setFullTableData] = useState([]); //dados para tabela de shipping financial
  const [tableData, setTableData] = useState([]);
  const [tableFilters, setTableFilters] = useState([]);

  const [formItemProducts, setFormItemProducts] = useState([]);
  const [formItemProductsLoader, setFormItemProductsLoader] = useState(true);
  const [formItemDocuments, setFormItemDocuments] = useState([]);
  const [formItemDocumentsLoader, setFormItemDocumentsLoader] = useState(true);
  const [formItemFinancial, setFormItemFinancial] = useState([]);
  const [formItemFinancialLoader, setFormItemFinancialLoader] = useState(true);
  
  //Setar o valor total das linhas selecionadas.
  const [valorTotal, setTotalReceived] = useState([]);
  const [currencyValue, setCurrencyValue] = useState('');
  
  const [tableOverview, setTableOverview] = useState({});
  const [overviewFilters, setOverviewFilters] = useState([]);

  //guarda a qtd de shippings de cada status prepaid
  const [qtdShippedPrepaid, setQtdShippedPrepaid] = useState(0);
  const [qtdConteinerPrepaid, setQtdConteinerPrepaid] = useState(0);
  const [qtdInTransitPrepaid, setQtdInTransitPrepaid] = useState(0);
  const [qtdLoadedPlantPrepaid, setQtdLoadedPlantPrepaid] = useState(0);
  const [qtdScheduledPrepaid, setQtdScheduledPrepaid] = useState(0);
  const [qtdUnderProPrepaid, setQtdUnderProPrepaid] = useState(0)
  const [qtdToProducedPrepaid, setQtdToProducedPrepaid] = useState(0);
  
  //guarda a qtd de shippings de cada status pending
  const [qtdShippedPending, setQtdShippedPending] = useState(0);
  const [qtdConteinerPending, setQtdConteinerPending] = useState(0);
  const [qtdInTransitPending, setQtdInTransitPending] = useState(0);
  const [qtdLoadedPlantPending, setQtdLoadedPlantPending] = useState(0);
  const [qtdScheduledPending, setQtdScheduledPending] = useState(0);
  const [qtdUnderProPending, setQtdUnderProPending] = useState(0)
  const [qtdToProducedPending, setQtdToProducedPending] = useState(0);

  const [qtdShippedPPA, setQtdShippedPPA] = useState(0);
  const [qtdConteinerPPA, setQtdConteinerPPA] = useState(0);
  const [qtdInTransitPPA, setQtdInTransitPPA] = useState(0);
  const [qtdLoadedPlantPPA, setQtdLoadedPlantPPA] = useState(0);
  const [qtdScheduledPPA, setQtdScheduledPPA] = useState(0);
  const [qtdUnderProPPA, setQtdUnderProPPA] = useState(0)
  const [qtdToProducedPPA, setQtdToProducedPPA] = useState(0);

  //guarda o estado do checkbox prepaid
  const [checkedShippedPrepaid, setCheckedShippedPrepaid] = useState(false);
  const [checkedConteinerPrepaid, setCheckedConteinerPrepaid] = useState(false);
  const [checkedInTransitPrepaid, setCheckedInTransitPrepaid] = useState(false);
  const [checkedLoadedPlantPrepaid, setCheckedLoadedPlantPrepaid] = useState(false);
  const [checkedScheduledPrepaid, setCheckedScheduledPrepaid] = useState(false);
  const [checkedUnderProPrepaid, setCheckedUnderProPrepaid] = useState(false);
  const [checkedToProducedPrepaid, setCheckedToProducedPrepaid] = useState(false);
  const [checkedAllPrepaid, setCheckedAllPrepaid] = useState(false);

  //guarda o estado do checkbox pending
  const [checkedShippedPending, setCheckedShippedPending] = useState(false);
  const [checkedConteinerPending, setCheckedConteinerPending] = useState(false);
  const [checkedInTransitPending, setCheckedInTransitPending] = useState(false);
  const [checkedLoadedPlantPending, setCheckedLoadedPlantPending] = useState(false);
  const [checkedScheduledPending, setCheckedScheduledPending] = useState(false);
  const [checkedUnderProPending, setCheckedUnderProPending] = useState(false);
  const [checkedToProducedPending, setCheckedToProducedPending] = useState(false);
  const [checkedAllPending, setCheckedAllPending] = useState(false);

  const [checkedShippedPPA, setCheckedShippedPPA] = useState(false);
  const [checkedConteinerPPA, setCheckedConteinerPPA] = useState(false);
  const [checkedInTransitPPA, setCheckedInTransitPPA] = useState(false);
  const [checkedLoadedPlantPPA, setCheckedLoadedPlantPPA] = useState(false);
  const [checkedScheduledPPA, setCheckedScheduledPPA] = useState(false);
  const [checkedUnderProPPA, setCheckedUnderProPPA] = useState(false);
  const [checkedToProducedPPA, setCheckedToProducedPPA] = useState(false);
  const [checkedAllPPA, setCheckedAllPPA] = useState(false);

  //colunas da tabela
  const [headerData, setHeaderData] = useState([
    'Currency',
    'Total Amount',
    '% PPA Requested',
    'Advanced Amount',
    'Payment Received',
    'Missing Prepayment',
  ]);


  useEffect(() => {
    getTableData();
    setOverviewLoading(true);
  }, [account]);

  useEffect(() => {
    setFormItemProductsLoader(false);
    setFormItemDocumentsLoader(false);
    setFormItemFinancialLoader(false);
  }, [formItemProducts, formItemDocuments, formItemFinancial]);

  const handleDataFilterBlockClose = async (status, prepayment) => {
    let item = document.querySelector(
      `div[data-status="${status}"][data-prepayment="${prepayment}"]`,
    );
    item.click();
  };

  const handleTableFilterBlockClose = async (item) => {
    let [[key, value]] = Object.entries(item);
    let data = [...tableData];
    setTableFilters(tableFilters.filter((filter) => JSON.stringify(Object.entries(filter)[0]) !== JSON.stringify([key, value])));
    await setTableData([]);
    await setFormItem({});
    await setTableData(data);
  };

  const handleItemClick = (item, page) => {
    setFormDisplay('shipping');
    setFormItem(item);
    setTableStartPage(page);
    let pageTop = document.querySelector('.pageContent');
    pageTop.scrollTop = 10000;
  };


  const handleChangeChecked = (e) => {
    let checkedShippedPrepaidAux = checkedShippedPrepaid
    let checkedConteinerPrepaidAux = checkedConteinerPrepaid
    let checkedInTransitPrepaidAux = checkedInTransitPrepaid
    let checkedLoadedPlantPrepaidAux = checkedLoadedPlantPrepaid
    let checkedScheduledPrepaidAux = checkedScheduledPrepaid
    let checkedUnderProPrepaidAux = checkedUnderProPrepaid
    let checkedToProducedPrepaidAux = checkedToProducedPrepaid
    let checkedAllPrepaidAux = checkedAllPrepaid

    let checkedShippedPendingAux = checkedShippedPending
    let checkedConteinerPendingAux = checkedConteinerPending
    let checkedInTransitPendingAux = checkedInTransitPending
    let checkedLoadedPlantPendingAux = checkedLoadedPlantPending
    let checkedScheduledPendingAux = checkedScheduledPending
    let checkedUnderProPendingAux = checkedUnderProPending
    let checkedToProducedPendingAux = checkedToProducedPending
    let checkedAllPendingAux = checkedAllPending

    let checkedShippedPPAAux = checkedShippedPPA
    let checkedConteinerPPAAux = checkedConteinerPPA
    let checkedInTransitPPAAux = checkedInTransitPPA
    let checkedLoadedPlantPPAAux = checkedLoadedPlantPPA
    let checkedScheduledPPAAux = checkedScheduledPPA
    let checkedUnderProPPAAux = checkedUnderProPPA
    let checkedToProducedPPAAux = checkedToProducedPPA
    let checkedAllPPAAux = checkedAllPPA

    switch (e.target.name) {
      case "checkedShippedPrepaid":
        setCheckedShippedPrepaid(e.target.checked);
        checkedShippedPrepaidAux = e.target.checked;
        break;

      case "checkedShippedPending":
        setCheckedShippedPending(e.target.checked);
        checkedShippedPendingAux = e.target.checked;
        break;

      case "checkedShippedPPA":
        setCheckedShippedPPA(e.target.checked);
        checkedShippedPPAAux = e.target.checked;
        break;

      case "checkedConteinerPrepaid":
        setCheckedConteinerPrepaid(e.target.checked);
        checkedConteinerPrepaidAux = e.target.checked;
        break;

      case "checkedConteinerPending":
        setCheckedConteinerPending(e.target.checked);
        checkedConteinerPendingAux = e.target.checked;
        break;

      case "checkedConteinerPPA":
        setCheckedConteinerPPA(e.target.checked);
        checkedConteinerPPAAux = e.target.checked;
        break;

      case "checkedInTransitPrepaid":
        setCheckedInTransitPrepaid(e.target.checked);
        checkedInTransitPrepaidAux = e.target.checked;
        break;

      case "checkedInTransitPending":
        setCheckedInTransitPending(e.target.checked);
        checkedInTransitPendingAux = e.target.checked;
        break;

      case "checkedInTransitPPA":
        setCheckedInTransitPPA(e.target.checked);
        checkedInTransitPPAAux = e.target.checked;
        break;

      case "checkedLoadedPlantPrepaid":
        setCheckedLoadedPlantPrepaid(e.target.checked);
        checkedLoadedPlantPrepaidAux = e.target.checked;
        break;

      case "checkedLoadedPlantPending":
        setCheckedLoadedPlantPending(e.target.checked);
        checkedLoadedPlantPendingAux = e.target.checked;
        break;

      case "checkedLoadedPlantPPA":
        setCheckedLoadedPlantPPA(e.target.checked);
        checkedLoadedPlantPPAAux = e.target.checked;
        break;

      case "checkedScheduledPrepaid":
        setCheckedScheduledPrepaid(e.target.checked);
        checkedScheduledPrepaidAux = e.target.checked;
        break;

      case "checkedScheduledPending":
        setCheckedScheduledPending(e.target.checked);
        checkedScheduledPendingAux  = e.target.checked;
        break;

      case "checkedScheduledPPA":
        setCheckedScheduledPPA(e.target.checked);
        checkedScheduledPPAAux  = e.target.checked;
        break;

      case "checkedUnderProPrepaid":
        setCheckedUnderProPrepaid(e.target.checked);
        checkedUnderProPrepaidAux = e.target.checked;
        break;

      case "checkedUnderProPending":
        setCheckedUnderProPending(e.target.checked);
        checkedUnderProPendingAux = e.target.checked;
        break;

      case "checkedUnderProPPA":
        setCheckedUnderProPPA(e.target.checked);
        checkedUnderProPPAAux = e.target.checked;
        break;

      case "checkedToProducedPrepaid":
        setCheckedToProducedPrepaid(e.target.checked);
        checkedToProducedPrepaidAux = e.target.checked;
        break;

      case "checkedToProducedPending":
        setCheckedToProducedPending(e.target.checked);
        checkedToProducedPendingAux = e.target.checked;
        break;

      case "checkedToProducedPPA":
        setCheckedToProducedPPA(e.target.checked);
        checkedToProducedPPAAux = e.target.checked;
        break;

      case "checkedAllPrepaid":
        setCheckedAllPrepaid(e.target.checked)
        checkedAllPrepaidAux = e.target.checked;
        break;

      case "checkedAllPending":
        setCheckedAllPending(e.target.checked)
        checkedAllPendingAux = e.target.checked;
        break;

      case "checkedAllPPA":
        setCheckedAllPPA(e.target.checked)
        checkedAllPPAAux = e.target.checked;
        break;

      default:
        break;
    }

    filterTable(
      checkedShippedPrepaidAux,
      checkedConteinerPrepaidAux,
      checkedInTransitPrepaidAux,
      checkedLoadedPlantPrepaidAux,
      checkedScheduledPrepaidAux,
      checkedUnderProPrepaidAux,
      checkedToProducedPrepaidAux,
      checkedAllPrepaidAux,
      checkedShippedPendingAux,
      checkedConteinerPendingAux,
      checkedInTransitPendingAux,
      checkedLoadedPlantPendingAux,
      checkedScheduledPendingAux,
      checkedUnderProPendingAux,
      checkedToProducedPendingAux,
      checkedAllPendingAux,
      checkedShippedPPAAux,
      checkedConteinerPPAAux,
      checkedInTransitPPAAux,
      checkedLoadedPlantPPAAux,
      checkedScheduledPPAAux,
      checkedUnderProPPAAux,
      checkedToProducedPPAAux,
      checkedAllPPAAux,
    );
  }

  const filterTable = async (
    checkedShippedPrepaid,
    checkedConteinerPrepaid,
    checkedInTransitPrepaid,
    checkedLoadedPlantPrepaid,
    checkedScheduledPrepaid,
    checkedUnderProPrepaid,
    checkedToProducedPrepaid,
    checkedAllPrepaid,
    checkedShippedPending,
    checkedConteinerPending,
    checkedInTransitPending,
    checkedLoadedPlantPending,
    checkedScheduledPending,
    checkedUnderProPending,
    checkedToProducedPending,
    checkedAllPending,
    checkedShippedPPA,
    checkedConteinerPPA,
    checkedInTransitPPA,
    checkedLoadedPlantPPA,
    checkedScheduledPPA,
    checkedUnderProPPA,
    checkedToProducedPPA,
    checkedAllPPA
  ) => {
    let filteredData = [];

    if (checkedAllPrepaid || (
          checkedShippedPrepaid &&
          checkedConteinerPrepaid &&
          checkedInTransitPrepaid &&
          checkedLoadedPlantPrepaid &&
          checkedScheduledPrepaid &&
          checkedUnderProPrepaid &&
          checkedToProducedPrepaid)) {

      filteredData = fullTableData.filter((item) => {
        return item.FlagPrepaid == 1 && item.Status != 'Settled'
      })

    } else {

      if (checkedShippedPrepaid) {
        fullTableData.filter((item) => {
          if (item.Status == 'Shipped' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedConteinerPrepaid) {
        fullTableData.filter((item) => {
          if (item.Status == 'Container at the Port' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedInTransitPrepaid) {
        fullTableData.filter((item) => {
          if(item.Status == 'In Transit' && item.FlagPrepaid == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedLoadedPlantPrepaid) {
        fullTableData.filter((item) => {
           if(item.Status == 'Loaded on the Plant' && item.FlagPrepaid == 1){
              filteredData.push(item);
           }
        })
      }

      if (checkedScheduledPrepaid) {
        fullTableData.filter((item) => {
          if(item.Status == 'Scheduled to be loaded' && item.FlagPrepaid == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedUnderProPrepaid) {
        fullTableData.filter((item) => {
          if(item.Status == 'Under Production' && item.FlagPrepaid == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedToProducedPrepaid) {
        fullTableData.filter((item) => {
          if(item.Status == 'To be Produced' && item.FlagPrepaid == 1){
            filteredData.push(item);
          }
        })
      }
    }

    if (checkedAllPending || (
      checkedShippedPending &&
      checkedConteinerPending &&
      checkedInTransitPending &&
      checkedLoadedPlantPending &&
      checkedScheduledPending &&
      checkedUnderProPending &&
      checkedToProducedPending)) {

    filteredData = fullTableData.filter((item) => {
      return item.FlagPending == 1 && item.Status != 'Settled'
    })

    } else {

      if (checkedShippedPending) {
        fullTableData.filter((item) => {
          if(item.Status == 'Shipped' && item.FlagPending == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedConteinerPending) {
        fullTableData.filter((item) => {
          if(item.Status == 'Container at the Port' && item.FlagPending == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedInTransitPending) {
        fullTableData.filter((item) => {
          if(item.Status == 'In Transit' && item.FlagPending == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedLoadedPlantPending) {
        fullTableData.filter((item) => {
          if(item.Status == 'Loaded on the Plant' && item.FlagPending == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedScheduledPending) {
        fullTableData.filter((item) => {
          if(item.Status == 'Scheduled to be loaded' && item.FlagPending == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedUnderProPending) {
        fullTableData.filter((item) => {
          if(item.Status == 'Under Production' && item.FlagPending == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedToProducedPending) {
        fullTableData.filter((item) => {
          if(item.Status == 'To be Produced' && item.FlagPending == 1){
            filteredData.push(item);
          }
        })
      }
    }

    if (checkedAllPPA || (
      checkedShippedPPA &&
      checkedConteinerPPA &&
      checkedInTransitPPA &&
      checkedLoadedPlantPPA &&
      checkedScheduledPPA &&
      checkedUnderProPPA &&
      checkedToProducedPPA)) {

      filteredData = fullTableData.filter((item) => {
        return item['Prepayment Status'] == 1 && item.Status != 'Settled'
      })
    } else {

        if (checkedShippedPPA) {
          fullTableData.filter((item) => {
          if(item.Status == 'Shipped' && item['Prepayment Status'] == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedConteinerPPA) {
        fullTableData.filter((item) => {
          if(item.Status == 'Container at the Port' && item['Prepayment Status'] == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedInTransitPPA) {
        fullTableData.filter((item) => {
          if(item.Status == 'In Transit' && item['Prepayment Status'] == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedLoadedPlantPPA) {
        fullTableData.filter((item) => {
          if(item.Status == 'Loaded on the Plant' && item['Prepayment Status'] == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedScheduledPPA) {
        fullTableData.filter((item) => {
          if(item.Status == 'Scheduled to be loaded' && item['Prepayment Status'] == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedUnderProPPA) {
        fullTableData.filter((item) => {
          if(item.Status == 'Under Production' && item['Prepayment Status'] == 1){
            filteredData.push(item);
          }
        })
      }

      if (checkedToProducedPPA) {
        fullTableData.filter((item) => {
          if(item.Status == 'To be Produced' && item['Prepayment Status'] == 1){
            filteredData.push(item);
          }
        })
      }
    }

  await setTableData([]);
  await setFormItem({});
  await setTableData(filteredData);
  setTableStartPage(1);
}

  const getTableData = async () => {
    let shippedPrepaid = 0;
    let conteinerPrepaid = 0;
    let inTransitPrepaid = 0;
    let loadedPlantPrepaid = 0
    let scheduledPrepaid = 0;
    let underProductionPrepaid = 0;
    let toProducedPrepaid = 0;

    let shippedPending = 0;
    let conteinerPending = 0;
    let inTransitPending = 0;
    let loadedPlantPending = 0
    let scheduledPending = 0;
    let underProductionPending = 0;
    let toProducedPending = 0;

    let shippedPPA = 0;
    let conteinerPPA = 0;
    let inTransitPPA = 0;
    let loadedPlantPPA = 0
    let scheduledPPA = 0;
    let underProductionPPA = 0;
    let toProducedPPA = 0;

    // let array = await Utils.getShippingData(account.erpConta, account.regionalContato, account.areaContato);
    let array = await Utils.getShippingData(account, { name: isLogedIn.name, email: JSON.parse(localStorage.getItem('logedInUser')).email });
    
    const arrayComRepeticao = array;
  
    const propriedadeChave = 'IdShipping';
    
    const mapa = new Map();
    
    for (const item of arrayComRepeticao) {
        const chave = item[propriedadeChave];
        
        if (!mapa.has(chave)) {
            mapa.set(chave, item);
        }
    }
    
    const arraySemRepeticao = Array.from(mapa.values());
      
    for (let i = 0; i <arraySemRepeticao.length; i++) {
      if (arraySemRepeticao[i].FlagPrepaid == 1) { //verifica se shipping possui status pending
        if(arraySemRepeticao[i].Status == 'Shipped'){
          shippedPrepaid += 1;
        }
        if(arraySemRepeticao[i].Status == 'Container at the Port'){
          conteinerPrepaid += 1;
        }
        if(arraySemRepeticao[i].Status == 'In Transit'){
          inTransitPrepaid += 1;
        }
        if(arraySemRepeticao[i].Status == 'Loaded on the Plant'){
          loadedPlantPrepaid += 1;
        }
        if((arraySemRepeticao[i].Status).toLowerCase() == 'scheduled to be loaded'){
          scheduledPrepaid += 1;
        }
        if(arraySemRepeticao[i].Status == 'Under Production'){
          underProductionPrepaid += 1;
        }
        if(arraySemRepeticao[i].Status == 'To be Produced'){
          toProducedPrepaid += 1;
        }
      } else if (arraySemRepeticao[i].FlagPending == 1) { //verifica se shipping possui status pending
          if(arraySemRepeticao[i].Status == 'Shipped'){
            shippedPending += 1;
          }
          if(arraySemRepeticao[i].Status == 'Container at the Port'){
            conteinerPending += 1;
          }
          if(arraySemRepeticao[i].Status == 'In Transit'){
            inTransitPending += 1;
          }
          if(arraySemRepeticao[i].Status == 'Loaded on the Plant'){
            loadedPlantPending += 1;
          }
          if((arraySemRepeticao[i].Status).toLowerCase() == 'scheduled to be loaded'){
            scheduledPending += 1;
          }
          if(arraySemRepeticao[i].Status == 'Under Production'){
            underProductionPending += 1;
          }
          if(arraySemRepeticao[i].Status == 'To be Produced'){
            toProducedPending += 1;
          }
      }

      if (arraySemRepeticao[i]['Prepayment Status'] == 1 && arraySemRepeticao[i].Status != 'Settled') { //verifica se exige pré pagamento
          if(arraySemRepeticao[i].Status == 'Shipped'){
            shippedPPA += 1;
          }
          if(arraySemRepeticao[i].Status == 'Container at the Port'){
            conteinerPPA += 1;
          }
          if(arraySemRepeticao[i].Status == 'In Transit'){
            inTransitPPA += 1;
          }
          if(arraySemRepeticao[i].Status == 'Loaded on the Plant'){
            loadedPlantPPA += 1;
          }
          if((arraySemRepeticao[i].Status).toLowerCase() == 'scheduled to be loaded'){
            scheduledPPA += 1;
          }
          if(arraySemRepeticao[i].Status == 'Under Production'){
            underProductionPPA += 1;
          }
          if(arraySemRepeticao[i].Status == 'To be Produced'){
            toProducedPPA += 1;
          }
      }
    }

    setQtdShippedPrepaid(shippedPrepaid);
    setQtdConteinerPrepaid(conteinerPrepaid);
    setQtdInTransitPrepaid(inTransitPrepaid);
    setQtdLoadedPlantPrepaid(loadedPlantPrepaid);
    setQtdScheduledPrepaid(scheduledPrepaid);
    setQtdUnderProPrepaid(underProductionPrepaid);
    setQtdToProducedPrepaid(toProducedPrepaid);

    setQtdShippedPending(shippedPending);
    setQtdConteinerPending(conteinerPending);
    setQtdInTransitPending(inTransitPending);
    setQtdLoadedPlantPending(loadedPlantPending);
    setQtdScheduledPending(scheduledPending);
    setQtdUnderProPending(underProductionPending);
    setQtdToProducedPending(toProducedPending);

    setQtdShippedPPA(shippedPPA);
    setQtdConteinerPPA(conteinerPPA);
    setQtdInTransitPPA(inTransitPPA);
    setQtdLoadedPlantPPA(loadedPlantPPA);
    setQtdScheduledPPA(scheduledPPA);
    setQtdUnderProPPA(underProductionPPA);
    setQtdToProducedPPA(toProducedPPA);



    setOverviewLoading(false);
    setFullTableData(arraySemRepeticao);
  };

  const handleFormMenuButton = async (evt, action) => {
    const menuItem = evt.currentTarget;
    const isSelected = menuItem.classList.contains('selected');
    if (isSelected) return false;

    setFormDisplay(action);
    menuItem.classList.add('selected');
    if (action === 'products') {
      setFormItemProductsLoader(true);
      let products = await Utils.getProducts(formItem.IdShipping);
      setFormItemProducts(products);
    }
    if (action === 'documents') {
      setFormItemDocumentsLoader(true);
      let documents = await Utils.getDocuments(formItem.IdShipping);

      console.log(documents);
      setFormItemDocuments(documents);
      
    }
    if(action === 'financial'){
      setFormItemFinancialLoader(true);
      let financial = await Utils.getFinancial(formItem['Contract&Load']);
      setFormItemFinancial(financial);
    }
  };

  const handleClickDownload = async (GUID, FileName) => {
    const { Conteudo } = await Utils.getDocumentBase64(GUID);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = 'data:application/octet-stream;base64,' + Conteudo;
    a.download = FileName;
    document.body.appendChild(a);
    a.click();
  };

  const Form = () => {
    return (
      <div className="form">
        <div className="menu noselect">
          <div
            className="backButton button"
            onClick={() => {
              setFormItem({});
            }}
          >
            <img src="/images/icons/arrow-left-outline-icon_blue.svg" alt="" />
            <p>Back</p>
          </div>
          <div
            className="ProductsButton button selected"
            onClick={(e) => {
              handleFormMenuButton(e, 'shipping');
            }}
          >
            <ShippingIcon />
            <p>Shipping</p>
          </div>
          <div
            className="ProductsButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'products');
            }}
          >
            <BoxIcon />
            <p>Products</p>
          </div>
          <div
            className="ProductsButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'documents');
            }}
          >
            <FolderIcon />
            <p>Documents</p>
          </div>
          <div
            className="FinancialButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'financial');
            }}>
              <FolderIcon />
              <p>Financial</p>
          </div>
        </div>
        <div className="formContent">
          <div className="title">
            <h1>Contract&Load: {formItem['Contract&Load']}</h1>
          </div>
          {formDisplay === 'shipping' && (
            <ul>
              {Object.keys(formItem).map((col, index) => {
                if (col == 'Contract&Load') return false;
                if (col == 'IdShipping') return false;
                return (
                  <li key={index}>
                    <p className="label">{col}:</p>{' '}
                    <p className="value">
                      {Utils.outputData(formItem[col], col)}
                    </p>
                  </li>
                );
              })}
            </ul>
          )}
          {formDisplay === 'products' && (
            <div className="contentProducts">
              {formItemProductsLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      {Object.keys(formItemProducts[0]).map((item) => (
                        <th>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formItemProducts.map((row) => {
                      return (
                        <tr>
                          {Object.values(row).map((item) => (
                            <td>{item}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {formDisplay === 'documents' && (
            <div className="contentDocuments">
              {formItemDocumentsLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <ul>
                  {formItemDocuments[0].FileName != "" ? (
                    formItemDocuments.map((document, index) => {
                      return (
                        <li key={index}>
                          <p className="label">{document.Categoria}:</p>
                          <p className="value">{document.FileName}</p>
                          <Button
                            style={{
                              width: 'auto',
                              height: 'auto',
                              padding: '7px 7px 5px 5px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            type="red"
                            caption="Download"
                            onClick={() => {
                              handleClickDownload(
                                document.GUID,
                                document.FileName,
                              );
                            }}
                            shape="square"
                          >
                            <DownloadIcon
                              width={20}
                              style={{
                                marginRight: '5px',
                                marginBottom: '2px',
                              }}
                            />
                          </Button>
                        </li>
                      );
                    })
                  ) : (
                    <li>
                      <p className="label" style={{ justifyContent: 'center' }}>
                        Shipping does not have documents to show
                      </p>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}
          {formDisplay === 'financial' && (
            <div className="contentFinancial">
              {formItemFinancialLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <>
                  <ul>
                    {formItemFinancial &&
                    formItemFinancial.length >= 1 ? (
                      formItemFinancial.map((document, index) => {
                        return (
                          <li key={index}>
                            <p>Justification: {document.Justification_c}</p>
                            <p>Payment value: {document.PaymentValue_c}</p>
                          </li>
                      );
                    })
                    ) : (
                      <li>
                        <p className="label" style={{ justifyContent: 'center' }}>
                          Shipping does not have financial files
                        </p>
                    </li>
                  )}
                  </ul>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="financialContent"
      tabIndex="0"
      onKeyUp={(e) => {
        if (e.which === 27) {
          setFormItem({});
        }
      }}
    >
      <div className="overview">
        {overviewLoading ? (
          <div className="loader">
            <Loader color="#E30613" />
          </div>
        ) : (
          <div className="overviewContent">
            <div className='filter'>
              <table style={{ marginRight: '100px' }} ref={ovTable}>
                <thead>
                  <tr>
                    <th>STATUS</th>
                    <th>TOTAL</th>
                    <th>PREPAID</th>
                    <th>PENDING</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Shipped</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedShippedPPA}
                        name="checkedShippedPPA"
                        defaultChecked={checkedShippedPPA}
                        onChange={handleChangeChecked}
                      />{qtdShippedPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedShippedPrepaid}
                        name="checkedShippedPrepaid"
                        defaultChecked={checkedShippedPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdShippedPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedShippedPending}
                        name="checkedShippedPending"
                        defaultChecked={checkedShippedPending}
                        onChange={handleChangeChecked}
                      />{qtdShippedPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Container at the Port</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedConteinerPPA}
                        name="checkedConteinerPPA"
                        defaultChecked={checkedConteinerPPA}
                        onChange={handleChangeChecked}
                      />{qtdConteinerPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedConteinerPrepaid}
                        name="checkedConteinerPrepaid"
                        defaultChecked={checkedConteinerPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdConteinerPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedConteinerPending}
                        name="checkedConteinerPending"
                        defaultChecked={checkedConteinerPending}
                        onChange={handleChangeChecked}
                      />{qtdConteinerPending}
                    </td>
                  </tr>
                  <tr>
                    <td>In Transit</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedInTransitPPA}
                        name="checkedInTransitPPA"
                        defaultChecked={checkedInTransitPPA}
                        onChange={handleChangeChecked}
                      />{qtdInTransitPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedInTransitPrepaid}
                        name="checkedInTransitPrepaid"
                        defaultChecked={checkedInTransitPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdInTransitPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedInTransitPending}
                        name="checkedInTransitPending"
                        defaultChecked={checkedInTransitPending}
                        onChange={handleChangeChecked}
                      />{qtdInTransitPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Loaded on the Plant</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedLoadedPlantPPA}
                        name="checkedLoadedPlantPPA"
                        defaultChecked={checkedLoadedPlantPPA}
                        onChange={handleChangeChecked}
                      />{qtdLoadedPlantPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedLoadedPlantPrepaid}
                        name="checkedLoadedPlantPrepaid"
                        defaultChecked={checkedLoadedPlantPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdLoadedPlantPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedLoadedPlantPending}
                        name="checkedLoadedPlantPending"
                        defaultChecked={checkedLoadedPlantPending}
                        onChange={handleChangeChecked}
                      />{qtdLoadedPlantPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Scheduled to be Loaded</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedScheduledPPA}
                        name="checkedScheduledPPA"
                        defaultChecked={checkedScheduledPPA}
                        onChange={handleChangeChecked}
                      />{qtdScheduledPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedScheduledPrepaid}
                        name="checkedScheduledPrepaid"
                        defaultChecked={checkedScheduledPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdScheduledPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedScheduledPending}
                        name="checkedScheduledPending"
                        defaultChecked={checkedScheduledPending}
                        onChange={handleChangeChecked}
                      />{qtdScheduledPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Under Production</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedUnderProPPA}
                        name="checkedUnderProPPA"
                        defaultChecked={checkedUnderProPPA}
                        onChange={handleChangeChecked}
                      />{qtdUnderProPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedUnderProPrepaid}
                        name="checkedUnderProPrepaid"
                        defaultChecked={checkedUnderProPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdUnderProPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedUnderProPending}
                        name="checkedUnderProPending"
                        defaultChecked={checkedUnderProPending}
                        onChange={handleChangeChecked}
                      />{qtdUnderProPending}
                    </td>
                  </tr>
                  <tr>
                    <td>To be Produced</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedToProducedPPA}
                        name="checkedToProducedPPA"
                        defaultChecked={checkedToProducedPPA}
                        onChange={handleChangeChecked}
                      />{qtdToProducedPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedToProducedPrepaid}
                        name="checkedToProducedPrepaid"
                        defaultChecked={checkedToProducedPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdToProducedPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedToProducedPending}
                        name="checkedToProducedPending"
                        defaultChecked={checkedToProducedPending}
                        onChange={handleChangeChecked}
                      />{qtdToProducedPending}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th> ALL</th>
                    <th>
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedAllPPA}
                        name="checkedAllPPA"
                        defaultChecked={checkedAllPPA}
                        onChange={handleChangeChecked}
                      />{qtdShippedPPA+qtdConteinerPPA+qtdInTransitPPA
                        +qtdLoadedPlantPPA+qtdScheduledPPA+qtdUnderProPPA+qtdToProducedPPA}
                    </th>
                    <th>
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedAllPrepaid}
                        name="checkedAllPrepaid"
                        defaultChecked={checkedAllPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdShippedPrepaid+qtdConteinerPrepaid+qtdInTransitPrepaid
                        +qtdLoadedPlantPrepaid+qtdScheduledPrepaid+qtdUnderProPrepaid+qtdToProducedPrepaid}
                    </th>
                    <th>
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedAllPending}
                        name="checkedAllPending"
                        defaultChecked={checkedAllPending}
                        onChange={handleChangeChecked}
                      />{qtdShippedPending+qtdConteinerPending+qtdInTransitPending
                        +qtdLoadedPlantPending+qtdScheduledPending+qtdUnderProPending+qtdToProducedPending}
                    </th> 
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className='total'>
              <table>
                <thead>
                  <tr>
                    <th>Selection</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total Missing</td>
                    <td>${valorTotal}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr></tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}
        {tableData.length > 0 ? (
          <div className="data">
            <Subtitle text="Filters" />
            {Object.keys(overviewFilters).length > 0 && (
              <div className="dataFilters">
                {Object.keys(overviewFilters).map((item) => {
                  return (
                    <div className="dataFilterBlock">
                      <div
                        className="close"
                        onClick={() => {
                          handleDataFilterBlockClose(
                            overviewFilters[item].Status,
                            overviewFilters[item].Prepayment,
                          );
                        }}
                      >
                        <img
                          className="cancelFilter"
                          src="/images/icons/plus-outline-icon.svg"
                          alt="Remove Filter"
                          title="Remove Filter"
                        />
                      </div>
                      <p>
                        <span className="dataFilterTitle">Status</span>:
                        <span className="dataFilterText">
                          {overviewFilters[item].Status}
                        </span>
                      </p>
                      <p>
                        <span className="dataFilterTitle">Prepayment</span>:
                        <span className="dataFilterText">
                          {overviewFilters[item].Prepayment}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            {tableFilters.length > 0 && (
              <div className="dataFilters">
                {tableFilters.map((item) => {
                  let [[key, value]] = Object.entries(item);
                  return (
                    <div className="dataFilterBlock">
                      <div
                        className="close"
                        onClick={() => {
                          handleTableFilterBlockClose(item);
                        }}
                      >
                        <img
                          className="cancelFilter"
                          src="/images/icons/plus-outline-icon.svg"
                          alt="Remove Filter"
                          title="Remove Filter"
                        />
                      </div>
                      <p>
                        <span className="dataFilterTitle">{key}</span>:
                        <span className="dataFilterText">{value}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}

            {Object.keys(formItem).length > 0 ? (
              Form()
            ) : (
              <FinanceTable
                data={tableData}
                header={headerData}
                startPage={tableStartPage || 1}
                itemClick={handleItemClick}
                idCol="Contract&Load"
                setTableFilters={setTableFilters}
                setTotalReceived={setTotalReceived}
                cache="schedule"
              />
            )}
          </div>
        ) : (
          <div className="data">
            <FinanceTable
              data={[]}
              header={headerData}
              startPage={tableStartPage || 1}
              itemClick={handleItemClick}
              idCol="Contract&Load"
              cache="schedule"
            />
          </div>
        )}
      </div>
    </div >
  )
};

export default Financial;
