import React, { useEffect, useState, useRef, useContext } from 'react';
import Loader from 'react-dots-loader';

import { Utils, Subtitle, Checkbox, Button } from '../../../Components/Components';
import BalanceTable from '../../../Components/Table/BalanceTable';

import { UserContext } from '../../../Components/Contexts';

import 'react-dots-loader/index.css';
import './Financial.scss';

import { ReactComponent as BoxIcon } from '../../../Components/images/icons/box.svg';
import { ReactComponent as ShippingIcon } from '../../../Components/images/icons/shipping.svg';
import { ReactComponent as FolderIcon } from '../../../Components/images/icons/folder.svg';
import { ReactComponent as DownloadIcon } from '../../../Components/images/icons/cloud-download.svg';


//página de Pending Balance
const FinancialBalance = () => {

  const { account, isLogedIn } = useContext(UserContext);
  const ovTable = useRef();


  const [formItem, setFormItem] = useState({});
  const [formDisplay, setFormDisplay] = useState('shipping');
  const [tableStartPage, setTableStartPage] = useState(1);

  const [overviewLoading, setOverviewLoading] = useState(true); //loader da pagina

  const [fullTableData, setFullTableData] = useState([]); //dados para tabela de shipping financial
  const [tableData, setTableData] = useState([]);
  const [tableFilters, setTableFilters] = useState([]);

  const [formItemProducts, setFormItemProducts] = useState([]);
  const [formItemProductsLoader, setFormItemProductsLoader] = useState(true);
  const [formItemDocuments, setFormItemDocuments] = useState([]);
  const [formItemDocumentsLoader, setFormItemDocumentsLoader] = useState(true);
  const [formItemFinancial, setFormItemFinancial] = useState([]);
  const [formItemFinancialLoader, setFormItemFinancialLoader] = useState(true);

  //Setar o valor total das linhas selecionadas.
  const [valorTotal, setTotalReceived] = useState([]);
  const [overdueQtd, setOverdueQtd] = useState(0);
  const [pendingBalanceQtd, setPendingBalanceQtd] = useState(0);
  const [checkedOverdue, setCheckedOverdue] = useState(false);
  const [checkedPendingBalance, setCheckedPendingBalance] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);

  const [isLessTotal, setIsLessTotal] = useState(false);
  const [currencyValue, setCurrencyValue] = useState('');


  const [tableOverview, setTableOverview] = useState({});
  const [overviewFilters, setOverviewFilters] = useState([]);
  //colunas da tabela
  const [headerData, setHeaderData] = useState([
    'Net Value',
    'Currency',
    'Received Prepayment',
    'Partial Balance Payment',
    'Pending Balance Value',
    '% Pending Balance',
    'Pending Balance',
    'Overdue',
  ]);

  useEffect(() => {
    getTableData();
    setOverviewLoading(true);
  }, [account]);

  useEffect(() => {
    setFormItemProductsLoader(false);
    setFormItemDocumentsLoader(false);
    setFormItemFinancialLoader(false);
  }, [formItemProducts, formItemDocuments, formItemFinancial]);


  const handleDataFilterBlockClose = async (status, prepayment) => {
    let item = document.querySelector(
      `div[data-status="${status}"][data-prepayment="${prepayment}"]`,
    );
    item.click();
  };

  const handleTableFilterBlockClose = async (item) => {
    let [[key, value]] = Object.entries(item);
    let data = [...tableData];
    setTableFilters(tableFilters.filter((filter) => JSON.stringify(Object.entries(filter)[0]) !== JSON.stringify([key, value])));
    await setTableData([]);
    await setFormItem({});
    await setTableData(data);
  };

  const handleItemClick = async (item, page) => {
    await setFormDisplay('shipping');
    await setFormItem(item);
    await setTableStartPage(page);
    let pageTop = document.querySelector('.pageContent');
    pageTop.scrollTop = 10000;
  };


  const handleChangeChecked = (e) => {
    let checkedOverdueAux = checkedOverdue;
    let checkedPendingBalanceAux = checkedPendingBalance;
    let checkedAllAux = checkedAll;

    switch (e.target.name) {
      case "checkedOverdue":
        setCheckedOverdue(e.target.checked);
        checkedOverdueAux = e.target.checked;
        break;
      case "checkedPendingBalance":
        setCheckedPendingBalance(e.target.checked);
        checkedPendingBalanceAux = e.target.checked;
        break;
      case "checkedAll":
        setCheckedAll(e.target.checked)
        checkedAllAux = e.target.checked;
        break;
      default:
        break;
    }

    filtertable(checkedAllAux, checkedOverdueAux, checkedPendingBalanceAux);
  }

  const filtertable = async (checkedAll, checkedOverdue, checkedPendingBalance) => {
    let filteredData = [];

    if (checkedAll || (checkedOverdue && checkedPendingBalance)) {

      filteredData = fullTableData.filter((item) => {
        return item.FlagOverdue == 1 || item.FlagPendingBalance == 1
      })

    } else {

      if (checkedOverdue) {
        filteredData = fullTableData.filter((item) => {
          return item.FlagOverdue == 1
        })
      }

      if (checkedPendingBalance) {
        filteredData = fullTableData.filter((item) => {
          return item.FlagPendingBalance == 1
        })
      }
    }

    await setTableData([]);
    await setFormItem({});
    await setTableData(filteredData);
    setTableStartPage(1);
  }


  const getTableData = async () => {
    let qtdOverdue = 0;
    let qtdPendingBalance = 0;

    let array = await Utils.getShippingData(account, { name: isLogedIn.name, email: JSON.parse(localStorage.getItem('logedInUser')).email });

    const arrayComRepeticao = array;
  
    const propriedadeChave = 'IdShipping';
    
    const mapa = new Map();
    
    for (const item of arrayComRepeticao) {
        const chave = item[propriedadeChave];
    
        if (!mapa.has(chave)) {
            mapa.set(chave, item);
        }
    }
    
    const arraySemRepeticao = Array.from(mapa.values());
  
    setCurrencyValue(arraySemRepeticao[0].Currency);


    for (let i = 0; i < arraySemRepeticao.length; i++) {
      if (arraySemRepeticao[i].FlagOverdue == 1) {
        qtdOverdue += 1;
      } else if (arraySemRepeticao[i].FlagPendingBalance == 1) {
        qtdPendingBalance += 1;
      }
    }
    setOverdueQtd(qtdOverdue);
    setPendingBalanceQtd(qtdPendingBalance)



    setOverviewLoading(false);
    setFullTableData(arraySemRepeticao);
  };

  const handleFormMenuButton = async (evt, action) => {
    const menuItem = evt.currentTarget;
    const isSelected = menuItem.classList.contains('selected');
    if (isSelected) return false;

    document.querySelectorAll('.form > .menu div').forEach((item) => {
      item.classList.remove('selected');
    });
    setFormDisplay(action);
    menuItem.classList.add('selected');
    if (action === 'products') {
      setFormItemProductsLoader(true);
      let products = await Utils.getProducts(formItem.IdShipping);
      setFormItemProducts(products);
    }
    if (action === 'documents') {
      setFormItemDocumentsLoader(true);
      let documents = await Utils.getDocuments(formItem.IdShipping);
      setFormItemDocuments(documents);
    }
    if(action === 'financial'){
      setFormItemFinancialLoader(true);
      let financial = await Utils.getFinancial(formItem['Contract&Load']);
      setFormItemFinancial(financial);
    }
  };

  const handleClickDownload = async (GUID, FileName) => {
    const { Conteudo } = await Utils.getDocumentBase64(GUID);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = 'data:application/octet-stream;base64,' + Conteudo;
    a.download = FileName;
    document.body.appendChild(a);
    a.click();
  };

  const Form = () => {
    return (
      <div className="form">
        <div className="menu noselect">
          <div
            className="backButton button"
            onClick={() => {
              setFormItem({});
            }}
          >
            <img src="/images/icons/arrow-left-outline-icon_blue.svg" alt="" />
            <p>Back</p>
          </div>
          <div
            className="ProductsButton button selected"
            onClick={(e) => {
              handleFormMenuButton(e, 'shipping');
            }}
          >
            <ShippingIcon />
            <p>Shipping</p>
          </div>
          <div
            className="ProductsButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'products');
            }}
          >
            <BoxIcon />
            <p>Products</p>
          </div>
          <div
            className="ProductsButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'documents');
            }}
          >
            <FolderIcon />
            <p>Documents</p>
          </div>
          <div
            className="FinancialButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'financial');
            }}>
              <FolderIcon />
              <p>Financial</p>
          </div>
        </div>
        <div className="formContent">
          <div className="title">
            <h1>Contract&Load: {formItem['Contract&Load']}</h1>
          </div>
          {formDisplay === 'shipping' && (
            <ul>
              {Object.keys(formItem).map((col, index) => {
                if (col == 'Contract&Load') return false;
                if (col == 'IdShipping') return false;
                return (
                  <li key={index}>
                    <p className="label">{col}:</p>{' '}
                    <p className="value">
                      {Utils.outputData(formItem[col], col)}
                    </p>
                  </li>
                );
              })}
            </ul>
          )}
          {formDisplay === 'products' && (
            <div className="contentProducts">
              {formItemProductsLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      {Object.keys(formItemProducts[0]).map((item) => (
                        <th>{item}</th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {formItemProducts.map((row) => {
                      return (
                        <tr>
                          {Object.values(row).map((item) => (
                            <td>{item}</td>
                          ))}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {formDisplay === 'documents' && (
            <div className="contentDocuments">
              {formItemDocumentsLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <ul>
                  {formItemDocuments[0].FileName != "" ? (
                    formItemDocuments.map((document, index) => {
                      return (
                        <li key={index}>
                          <p className="label">{document.Categoria}:</p>
                          <p className="value">{document.FileName}</p>
                          <Button
                            style={{
                              width: 'auto',
                              height: 'auto',
                              padding: '7px 7px 5px 5px',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                            type="red"
                            caption="Download"
                            onClick={() => {
                              handleClickDownload(
                                document.GUID,
                                document.FileName,
                              );
                            }}
                            shape="square"
                          >
                            <DownloadIcon
                              width={20}
                              style={{
                                marginRight: '5px',
                                marginBottom: '2px',
                              }}
                            />
                          </Button>
                        </li>
                      );
                    })
                  ) : (
                    <li>
                      <p className="label" style={{ justifyContent: 'center' }}>
                        Shipping does not have documents to show
                      </p>
                    </li>
                  )}
                </ul>
              )}
            </div>
          )}
          {formDisplay === 'financial' && (
            <div className="contentFinancial">
              {formItemFinancialLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <>
                  <ul>
                    {formItemFinancial &&
                    formItemFinancial.length >= 1 ? (
                      formItemFinancial.map((document, index) => {
                        return (
                          <li key={index}>
                            <p>Justification: {document.Justification_c}</p>
                            <p>Payment value: {document.PaymentValue_c}</p>
                          </li>
                      );
                    })
                    ) : (
                      <li>
                        <p className="label" style={{ justifyContent: 'center' }}>
                          Shipping does not have financial files
                        </p>
                    </li>
                  )}
                  </ul>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div
      className="financialContent"
      tabIndex="0"
      onKeyUp={(e) => {
        if (e.which === 27) {
          setFormItem({});
        }
      }}
    >
      <div className="overview">
        {overviewLoading ? (
          <div className="loader">
            <Loader color="#E30613" />
          </div>
        ) : (
          <div className="overviewContent">
            <div className='filter'>
              <table style={{ marginRight: '100px' }} ref={ovTable}>
                <thead>
                  <tr>
                    <th>STATUS</th>
                    <th> </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Overdue</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedOverdue}
                        name="checkedOverdue"
                        defaultChecked={checkedOverdue}
                        onChange={handleChangeChecked}
                      />{overdueQtd}
                    </td>
                  </tr>
                  <tr>
                    <td>Pending Balance</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedPendingBalance}
                        name="checkedPendingBalance"
                        defaultChecked={checkedPendingBalance}
                        onChange={handleChangeChecked}
                      />{pendingBalanceQtd}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th> ALL</th>
                    <th className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedAll}
                        name="checkedAll"
                        defaultChecked={checkedAll}
                        onChange={handleChangeChecked}
                      />{overdueQtd + pendingBalanceQtd}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <div className='total'>
              <table>
                <thead>
                  <tr>
                    <th>Selection</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Total</td>
                    <td>{currencyValue}{valorTotal}</td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr></tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}
        {tableData.length > 0 ? (
          <div className="data">
            <Subtitle text="Filters" />
            {Object.keys(overviewFilters).length > 0 && (
              <div className="dataFilters">
                {Object.keys(overviewFilters).map((item) => {
                  return (
                    <div className="dataFilterBlock">
                      <div
                        className="close"
                        onClick={() => {
                          handleDataFilterBlockClose(
                            overviewFilters[item].Status,
                            overviewFilters[item].Prepayment,
                          );
                        }}
                      >
                        <img
                          className="cancelFilter"
                          src="/images/icons/plus-outline-icon.svg"
                          alt="Remove Filter"
                          title="Remove Filter"
                        />
                      </div>
                      <p>
                        <span className="dataFilterTitle">Status</span>:
                        <span className="dataFilterText">
                          {overviewFilters[item].Status}
                        </span>
                      </p>
                      <p>
                        <span className="dataFilterTitle">Prepayment</span>:
                        <span className="dataFilterText">
                          {overviewFilters[item].Prepayment}
                        </span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}
            {tableFilters.length > 0 && (
              <div className="dataFilters">
                {tableFilters.map((item) => {
                  let [[key, value]] = Object.entries(item);
                  return (
                    <div className="dataFilterBlock">
                      <div
                        className="close"
                        onClick={() => {
                          handleTableFilterBlockClose(item);
                        }}
                      >
                        <img
                          className="cancelFilter"
                          src="/images/icons/plus-outline-icon.svg"
                          alt="Remove Filter"
                          title="Remove Filter"
                        />
                      </div>
                      <p>
                        <span className="dataFilterTitle">{key}</span>:
                        <span className="dataFilterText">{value}</span>
                      </p>
                    </div>
                  );
                })}
              </div>
            )}

            {Object.keys(formItem).length > 0 ? (
              Form()
            ) : (
              <BalanceTable
                data={tableData}
                header={headerData}
                startPage={tableStartPage || 1}
                itemClick={handleItemClick}
                idCol="Contract&Load"
                setTableFilters={setTableFilters}
                setTotalReceived={setTotalReceived}
                cache="schedule"
              />
            )}
          </div>
        ) : (
          <div className="data">
            <BalanceTable
              data={[]}
              header={headerData}
              startPage={tableStartPage || 1}
              itemClick={handleItemClick}
              idCol="Contract&Load"
              cache="schedule"
            />
          </div>
        )}
      </div>
    </div >
  )
};

export default FinancialBalance;
