// dependencies
// npm  install xlsx file-saver react-export-excel react-csv node-sass@4.14.1 --save

import React, { useEffect, useRef, useState, useContext } from 'react';

import { UserContext } from '../../Components/Contexts';

import Loader from 'react-dots-loader';
import 'react-dots-loader/index.css';

import { Utils, Button } from '../../Components/Components';

import './Table.scss';

import 'semantic-ui-css/semantic.min.css';

import { FileUploader } from "react-drag-drop-files";
import { Icon, Input } from 'semantic-ui-react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import ReactExport from 'react-export-excel';
import CsvDownloader from 'react-csv-downloader';

//Tabela para aba financeira
const FinanceTable = ({
  header = [],
  data = [],
  itemsPerPage = 10,
  startPage = 1,
  itemClick,
  idCol = '',
  fixedCols = [],
  hiddenCols = [],
  setTableFilters = Function,
  setTotalReceived = Function,
  noOrderBy = false,
  noExport = false,
  noChangeColumns = false,
  noCookie = false,
  cache = '',
  orderedCols = false,
  valorTotalFinal = ""
}) => {

  if(localStorage.getItem("itemPorPaginaPrepaid")){
    itemsPerPage = localStorage.getItem("itemPorPaginaPrepaid");
  }else{
    itemsPerPage = 10;
  }

  // Excel stuff
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  const exportMenu = useRef();
  const exportButton = useRef();

  const loaded = useRef(false);
  const tableContainer = useRef();
  const changeColumnMenu = useRef();
  const changeColumnButton = useRef();
  const orderByButton = useRef();
  const selectNumOfItemsPerPage = useRef();

  const [originalData, setOriginalData] = useState(data);
  const [numOfItemsPerPage, setNumOfItemsPerPage] = useState(itemsPerPage);
  const [totalRows, setTotalRows] = useState(data.length);
  const [allHeaders, setAllHeaders] = useState([]);
  const [tableHeader, setTableHeader] = useState(header);
  const [tableData, setTableData] = useState([]);
  const [pages, setPages] = useState(1);
  const [activePage, setActivePage] = useState(1);
  const [orderBy, setOrderBy] = useState({});
  const [columnFilter, setColumnFilter] = useState([]);
  const [searchTimeout, setSearchTimeout] = useState();
  const [exportVisibleColumns, setExportVisibleColumns] = useState(true);
  const [excelData, setExcelData] = useState([]);
  const [excelHeader, setExcelHeader] = useState([]);
  const [loading, setLoading] = useState(false);
  const [valorTotal, setValorTotal] = useState(0);
  const [orderByMenu, setOrderByMenu] = useState([
    'Estimated Value',
    'Prepaid',
    'Requested Prepayment',
    'Payment Received',
    'Missing Prepayment'
  ]);

  const [files, setFiles] = useState([]);
  const [arrayBase64, setArrayBase64] = useState([]);
  const [paymentVal, setPaymentVal] = useState();
  const [justificationVal, setJustificationVal] = useState('');
  const [arrayContractLoad, setArrayContractLoad] = useState([]);
  const [isLessTotal, setIsLessTotal] = useState(false);


  const [arrayIds, setarrayIds] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPaymentVal([]);
    setJustificationVal([]);
    setFiles([]);
    setArrayContractLoad([]);
    setArrayBase64([]);
  }


  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "TXT", "XLSX", "ZIP"];
  const fileNames = [];

  const { isLogedIn } = useContext(UserContext);

  
  const abaDePagamento = "Prepaid";

  useEffect(() => {
    generateTableData();
    
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => { }, [tableData, excelData]);

  useEffect(() => {
    gotoPage(activePage);
  }, [activePage]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loaded.current) return false;
    gotoPage(activePage);
  }, [numOfItemsPerPage, orderBy, columnFilter]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    let filteredData = [...originalData];
    let filterObject = {};

    if (columnFilter.length > 0) {
      columnFilter.map((item, index) => {
        let [[key, value]] = Object.entries(item);
        if (filterObject[key] === undefined) {
          filterObject[key] = [];
        }
        filterObject[key].push(
          ...filteredData.filter(
            (row) => row[key].toLowerCase().indexOf(value.toLowerCase()) >= 0,
          ),
        );
        return true;
      });

      Object.entries(filterObject).map((item) => {
        let [key, arr] = item;
        let found = [];
        found = filteredData.filter((row) => {
          let arrItem = arr.filter(
            (filterItem) => row[idCol] === filterItem[idCol],
          );
          if (arrItem.length > 0) return true;
          return false;
        });
        filteredData = found;
        setTableHeader(filteredData);
        return true;
      });
    }

    if (orderBy.mode === 'asc')
      filteredData.sort((a, b) => (a[orderBy.col] > b[orderBy.col] ? 1 : -1));
    if (orderBy.mode === 'desc')
      filteredData.sort((a, b) => (a[orderBy.col] < b[orderBy.col] ? 1 : -1));

    setExcelHeader(
      exportVisibleColumns ? [idCol, ...tableHeader] : [idCol, ...allHeaders],
    );
    setExcelData(filteredData);
  }, [exportVisibleColumns]); // eslint-disable-line react-hooks/exhaustive-deps

  const generateTableData = async () => {

    setLoading(true);
    
    setOriginalData([...data]);
    setTotalRows(data.length);
    setPages(Math.ceil(data.length / numOfItemsPerPage));
    setActivePage(startPage || 1);
    if (data[0] != null) {
      setAllHeaders(Object.keys(data[0]).sort((a, b) => (a > b ? 1 : -1)));
    }
    setLoading(false);
  };

  const ExportExcel = (data, header) => {

    data = JSON.parse(JSON.stringify(data));
    data.forEach((dataAux, index) => {
      // console.log("excell data", dataAux);
      Object.keys(dataAux).forEach((key) => {
        if (dataAux[key]?.includes('$') && dataAux[key]?.length > 1) {
          data[index][key] = dataAux[key].replace('$', '');
          data[index][key] = dataAux[key].replace('.', '');
        }
      });
    });

    return (
      <ExcelFile
        element={<button className="excelDownloadButton">.XLSX</button>}
        filename="Prepayment"
      >
        <ExcelSheet data={data} name="Prepayment">
          {header.map((item) => {
            return <ExcelColumn label={item} value={item} />;
          })}
        </ExcelSheet>
      </ExcelFile>
    );
  };

  const handleChangeFile = (fileUpload) => {
    setFiles([...files, fileUpload]);
  };

  const handleCheckBoxChange = async (e, row) => {
    let arrayIdsAux = [];
    if (e.target.checked) {

      arrayIds.push({
        IdShipping: row.IdShipping,
        missingPrepayment: row['Missing Prepayment'] ?? 0
      });
      
      arrayIdsAux = arrayIds;
      let ShippingId = row.IdShipping;
        
      arrayContractLoad.push({ShippingId});

    } else {
      let contractLoadAux = arrayContractLoad.filter((item) => {
        return item.IdShipping != row.IdShipping
      });

        arrayIdsAux = arrayIds.filter((item) => {
          return item.IdShipping != row.IdShipping
        });
 
      await setarrayIds(arrayIdsAux)
      await setArrayContractLoad(contractLoadAux);
    }

    let valorTotal = 0;
    arrayIdsAux.map((item) => {
      valorTotal = parseFloat(valorTotal) + parseFloat(item.missingPrepayment.replace(',', '').replace("$",''));
    });

    let numberFormat = Intl.NumberFormat('en-US', {minimumFractionDigits: 2});

    valorTotalFinal = numberFormat.format(valorTotal);

    setTotalReceived(valorTotalFinal);
    setValorTotal(valorTotalFinal);

    // console.log("ARRAY");
    // console.log(arrayContractLoad);
  };
  const ExportCsv = (data, header) => {
    data = JSON.parse(JSON.stringify(data));

    data.forEach((dataAux, index) => {
      Object.keys(dataAux).forEach((key) => {
        if (dataAux[key]?.includes('$') && dataAux[key]?.length > 1) {
          data[index][key] = dataAux[key].replace('$', '');
          data[index][key] = dataAux[key].replace('.', '');
        }
      });
    });

    return (
      <CsvDownloader
        filename="Prepayment"
        separator=";"
        columns={header}
        datas={data}
        text="DOWNLOAD"
      >
        <span>
          <button
            className="excelDownloadButton"
            onClick={() => {
              ExportCsv(excelData, excelHeader);
            }}
          >
            .CSV
          </button>
        </span>
      </CsvDownloader>
    );
  };

  const handleSelectColumn = (row) => {
    let arrayIds = [];
    let misssingValue = 0;

    arrayIds.push({
      IdShipping: row.IdShipping,
      missingPrepayment: row['Missing Prepayment'] ?? 0
    });

    let ShippingId = row.IdShipping;

    arrayContractLoad.push({ ShippingId });
    misssingValue = arrayIds.missingPrepayment;

    setValorTotal(valorTotalFinal);
    setTotalReceived(misssingValue);
  }


  const renderTableRow = (row) => {
    return (
      <tr
        key={row[idCol]}
        onClick={() => {
          if (itemClick) itemClick(row, activePage);
          handleSelectColumn(row);
        }}
      >
        <td>
          <div>
            <div>
              <div
                style={{float: 'left', marginRight: '5px'}}
              >
                <input  type="checkbox" id={row[idCol]} onClick={((e) => handleCheckBoxChange(e, row))} />
              </div>
              <div
                style={{
                  float: 'left',
                  marginRight: '5px'
                }}
              >
                <button
                  style={{width: '30px'}}
                  onClick={() => {
                    if (itemClick) itemClick(row, activePage);
                  }}
                >
                  <Icon name='folder open outline' />
                </button>
              </div>
            </div>
            <div>
            </div>
            <div
              style={{float: 'left'}}
            >
              {row[idCol]}
            </div>
          </div>
          {/* <div className="tooltip">
            Use the checkbox to select shippings to pay or open details in folder icon
          </div> */}
        </td>

        {fixedCols.map((col) => {
          if (col === idCol) return false;
          if (row[col] != null) return <td>{row[col]}</td>;
          else return '';
        })}
        {tableHeader.map((col) => {
          if (col === idCol) return false;
          if (col === 'IdShipping') return false;
          if (fixedCols.indexOf(col) >= 0) return false;
          if (row[col] != null)
            return <td>{Utils.outputData(row[col], col)}</td>;
          else return '';
        })}
      </tr>
    );
  };

  const gotoPage = (pageNum) => {
    if (pageNum < 0) return;
    if (pageNum > pages) return;

    let filteredData = [...originalData];
    let filterObject = {};

    if (columnFilter.length > 0) {
      columnFilter.map((item, index) => {
        let [[key, value]] = Object.entries(item);
        if (filterObject[key] === undefined) {
          filterObject[key] = [];
        }
        filterObject[key].push(
          ...filteredData.filter(
            (row) => row[key].toLowerCase().indexOf(value.toLowerCase()) >= 0,
          ),
        );
        return true;
      });

      Object.entries(filterObject).map((item) => {
        let [key, arr] = item;
        let found = [];
        found = filteredData.filter((row) => {
          let arrItem = arr.filter(
            (filterItem) => row[idCol] === filterItem[idCol],
          );
          if (arrItem.length > 0) return true;
          return false;
        });
        filteredData = found;
        return true;
      });
    }

    if (orderBy.mode === 'asc')
      filteredData.sort((a, b) => (a[orderBy.col] > b[orderBy.col] ? 1 : -1));
    if (orderBy.mode === 'desc')
      filteredData.sort((a, b) => (a[orderBy.col] < b[orderBy.col] ? 1 : -1));

    if (Math.ceil(filteredData.length / numOfItemsPerPage) < pageNum) {
      setActivePage(Math.ceil(filteredData.length / numOfItemsPerPage));
      return;
    }

    setTotalRows(filteredData.length);
    setPages(Math.ceil(filteredData.length / numOfItemsPerPage) || 1);

    setExcelHeader(exportVisibleColumns ? tableHeader : allHeaders);
    setExcelData(filteredData);

    filteredData = filteredData.slice((pageNum - 1) * numOfItemsPerPage);

    if (filteredData.length > numOfItemsPerPage) {
      filteredData.length = numOfItemsPerPage;
    }
    setTableData(filteredData);
    loaded.current = true;
  };

  const renderPages = () => {
    let items = [];
    if (activePage > 6) {
      items.push(
        <div
          onClick={() => {
            setActivePage(1);
          }}
        >
          {1}
        </div>,
      );
      items.push(<span>...</span>);
    }
    for (let i = activePage - 5; i <= activePage + 5; i++) {
      if (i > 0 && i <= pages) {
        items.push(
          <div
            className={i === activePage ? 'active' : ''}
            onClick={() => {
              setActivePage(i);
            }}
          >
            {i}
          </div>,
        );
      }
    }
    if (activePage < pages - 6) {
      items.push(<span>...</span>);
      items.push(
        <div
          onClick={() => {
            setActivePage(pages);
          }}
        >
          {pages}
        </div>,
      );
    }
    return items;
  };

  const toggleSearchCol = (evt) => {
    evt.target.parentElement.classList.toggle('active');
    if (evt.target.src.indexOf('search-white.svg') >= 0) {
      evt.target.src = '/images/icons/arrow-left-outline-icon.svg';
      evt.target.title = 'Cancel';
      evt.target.parentElement.childNodes[1].focus();
      evt.target.parentElement.scrollTo(0, 0);
    } else {
      evt.target.src = '/images/icons/search-white.svg';
      evt.target.title = 'Edit Filter';
    }
  };

  const handleColumnFilter = async (evt, col, text, timeout = 500) => {
    let newObj = {};
    if (text.length >= 2) {
      newObj = [...columnFilter, { [col]: text }];
    } else {
      newObj = [...columnFilter];
    }
    await setColumnFilter(newObj);
    await setTableFilters(newObj);
    // Comentei todos os setCache
    // Utils.setCache(cache, {
    //   columns: tableHeader,
    //   columnFilter: newObj,
    //   orderBy: orderBy,
    // });
  };

  const handleOrderBy = async (evt, col) => {
    let order = {};

    if (orderBy.col === col) {
      if (orderBy.mode === 'desc') {
        order = { col: '', mode: '' };
      } else {
        order = { col: col, mode: 'desc' };
      }
    } else {
      order = { col: col, mode: 'asc' };
    }
    await setOrderBy(order);
  };

  const handleSelectNumOfItemsPerPage = (evt) => {
    setNumOfItemsPerPage(evt.target.value);
    localStorage.setItem("itemPorPaginaPrepaid", evt.target.value);
  };


  const getBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.fileName = file.name;
      const nomeArquivo = reader.fileName;
      reader.onload = () => resolve(reader.result.substr(reader.result.indexOf(',') + 1));;
      reader.onerror = (error) => reject(error);

      fileNames.push(nomeArquivo);
    });

  const removeFile = file => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };

  const submitBase64Files = async (IdShipping, arrayContractLoad, fileName, conteudo, 
    justificationVal, paymentVal, email, valorTotal, abaDePagamento, clientName) => {
      
    let financialFile = await Utils.paymentFinancial(IdShipping, arrayContractLoad, fileName, conteudo, 
      justificationVal, paymentVal, email, valorTotal, abaDePagamento, clientName);

    return financialFile;
  };


  const stylebox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const handlePaymentValue = () =>{

    if(Math.abs(parseInt(valorTotal)) < parseInt(paymentVal)){
      setIsLessTotal(true);
      if(justificationVal.length > 10){
        setIsLessTotal(false);
      }
    }else{
      setIsLessTotal(false);
    }
  }

  // Render Stuff

  return (
    <div ref={tableContainer} className="tableContainer">
      <div className="tableOptions">
        <div className="numOfItems">
          <div
            className="back"
            onClick={() => {
              if (activePage === 1) return;
              setActivePage(activePage - 1);
            }}
          >
            <p className="arrow noselect">◂</p>
          </div>
          <div className="itemsText noselect">
            row{' '}
            <span className="red">
              {activePage * numOfItemsPerPage - numOfItemsPerPage + 1 < 0
                ? 0
                : activePage * numOfItemsPerPage - numOfItemsPerPage + 1}
            </span>{' '}
            to{' '}
            <span className="red">
              {activePage * numOfItemsPerPage > totalRows
                ? totalRows
                : activePage * numOfItemsPerPage || 0}
            </span>{' '}
            from <span className="red">{totalRows}</span>
          </div>
          <div
            className="forward"
            onClick={() => {
              if (activePage === pages) return;
              setActivePage(activePage + 1);
            }}
          >
            <p className="arrow noselect">▸</p>
          </div>
          <div className="numOfItemsPerPage noselect">
            <span>Show</span>
            <select
              ref={selectNumOfItemsPerPage}
              value={numOfItemsPerPage}
              onChange={handleSelectNumOfItemsPerPage}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <span>Items per Page</span>
          </div>
        </div>

        {!noExport && (
          <div className="export noselect" ref={exportButton}>
            <h1>Export</h1>
            <div ref={exportMenu} className="exportMenu">
              <h2>Choose file type</h2>
              {/* <div className="checkColumns">
                <input
                  value={exportVisibleColumns}
                  type="checkbox"
                  id="checkCol"
                  onClick={(e) => {
                    setExportVisibleColumns(e.target.checked);
                  }}
                />
                <label htmlFor="checkCol">Export only visible columns</label>
              </div> */}
              {excelData.length > 0 && ExportExcel(excelData, excelHeader)}
              {excelData.length > 0 && ExportCsv(excelData, excelHeader)}
            </div>
          </div>
        )}

        <div className="sendPayment">
          <Button
            style={{ backgroundColor: 'transparent', borderColor: 'transparent', boxShadow: 'none' }}
            onClick={handleOpen}
            caption="Send Payment Slips"
          />

          <Modal 
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={stylebox}>
              <div>
                <div>
                  <div style={{ textAlign: 'center', fontFamily: 'Roboto', color: 'rgba(13, 23, 79, 1)' }}>
                    <h1>UPLOAD PAYMENT VALUES</h1>
                    <h2>On this screen, upload the files referring to the selected Shippings</h2>
                  </div>
                </div>
              </div>
              <div className="dropzone">
                <FileUploader handleChange={handleChangeFile} name="file" types={fileTypes} />
                <div style={{ marginTop: '5px' }}>
                  {files.length ? (
                    files.map((item, index) =>
                      <>
                        <p key={index}
                          style={{
                            fontFamily: 'Roboto',
                            fontWeight: 700, // Roboto bold
                            justifyContent: 'space-between',
                          }}>File name: {item.name}
                          <button
                            style={{
                              float: 'right'
                            }}
                            onClick={() => removeFile(index)}
                          >
                            <Icon name='trash alternate' />
                          </button>
                        </p>
                      </>
                    )
                  ) : (
                    <p style={{ fontFamily: 'Roboto', fontWeight: 700 /* Roboto bold */, color: 'red' }}>No files uploaded yet</p>
                  )}
                </div>
              </div>
              <div style={{ fontFamily: 'Roboto', fontWeight: 700 /* Roboto bold */ }}>
                <p>Total: {valorTotal}</p>
              </div>
              <div className="inputLabelFinancial">
                <div className="paymentValue">
                  <div className="titlesInput">
                    <label>
                      Payment value:
                    </label>
                  </div>
                  <div className="inputFinancial">
                    <Input
                      type="number"
                      icon='dollar sign'
                      iconPosition='left'
                      placeholder='500,00'
                      value={paymentVal}
                      onInput={e => setPaymentVal(e.target.value)}
                      onChange={handlePaymentValue}
                    />
                  </div>
                </div>
                  <div className="justification">
                    <div className="titlesInput">
                      <label>
                        Justification
                      </label>
                    </div>
                    <div className="inputFinancial">
                      <Input
                        placeholder='This payment...'
                        style={{ width: '370px' }}
                        value={justificationVal}
                        onInput={e => setJustificationVal(e.target.value)}
                        onChange={handlePaymentValue}
                        />
                      {isLessTotal ? (
                        <p></p>
                      )
                      : (<p style={{ fontFamily: 'Roboto', fontWeight: 700 /* Roboto bold */, color: 'red' }}>You must justify when paying less than total</p>)}
                    </div>
                  </div>
              </div>
              <div className="submitButton">
                <Button
                  style={{
                    width: '120px',
                    height: 'auto',
                  }}
                  type="red"
                  caption="Submit"
                  shape="square"
                  onClick={async () => {
                    if (files.length >= 1 && (isLessTotal == true && justificationVal.length > 0) || paymentVal.length > 0) {
                      const { email } = await JSON.parse(localStorage.getItem('logedInUser'));
                      const clientName = localStorage.getItem('nameClient');

                      for (let i = 0; i < files.length; i++) {
                        const resultado = await getBase64(files[i]);
                        arrayBase64.push(resultado);
                    
                        for(let j=0;j<arrayContractLoad.length;j++){
                          submitBase64Files(arrayContractLoad[j], fileNames[i], resultado,
                            justificationVal, paymentVal, email, valorTotal, 
                            abaDePagamento, clientName);
                        }
                      }
                      alert('Send was sucessful! Your files will be sent by email to our financial sector');
                    } else {
                      alert('You must write a justification');
                    };
                    handleClose();
                  }}>
                </Button>
              </div>
            </Box>
          </Modal>

        </div>

        {!noOrderBy && (
          <div className="orderBy noselect" ref={orderByButton}>
            <h1>Order By</h1>
            <div ref={orderByMenu} className="orderByMenu">
              <ul>
                {orderBy.col === idCol ? (
                  <li
                    key={'id'}
                    className={orderBy.mode}
                    onClick={(evt) => {
                      handleOrderBy(evt, idCol);
                    }}
                  >
                    {idCol}
                  </li>
                ) : (
                  <li
                    key={'id'}
                    onClick={(evt) => {
                      handleOrderBy(evt, idCol);
                    }}
                  >
                    {idCol}
                  </li>
                )}
                {tableHeader.map((item, index) => {
                  if (orderBy.col === item) {
                    return (
                      <li
                        key={index}
                        className={orderBy.mode}
                        onClick={(evt) => {
                          handleOrderBy(evt, item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  } else {
                    return (
                      <li
                        key={index}
                        onClick={(evt) => {
                          handleOrderBy(evt, item);
                        }}
                      >
                        {item}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </div>
        )}
      </div>
      {loading ? (
        <Loader color="#E30613" />
      ) : (
        <div className="customTable">
          <table cellSpacing="0">
            <thead>
              <tr>
                <th className={orderBy.col === idCol ? orderBy.mode : ''}>
                  <div className="headerText">
                    <p>{idCol}</p>
                  </div>
                  <div className={`searchColContainer`}>
                    <img
                      onClick={toggleSearchCol}
                      src="/images/icons/search-white.svg"
                      alt=""
                      title="Edit Filter"
                    />
                    <input
                      className="searchCol"
                      type="text"
                      placeholder="Type and press enter"
                      onKeyUp={(e) => {
                        if (e.which === 13) {
                          e.target.parentElement.childNodes[0].click();
                          handleColumnFilter(e, idCol, e.target.value);
                        } else if (e.which === 27) {
                          e.target.value = '';
                          handleColumnFilter(e, idCol, '', 0);
                          e.target.parentElement.childNodes[0].click();
                        } else {
                        }
                      }}
                    />
                  </div>
                </th>
                {fixedCols.map((item, index) => {
                  if (item === idCol) return false;
                  if (allHeaders.indexOf(item) >= 0) {
                    return (
                      <th className={orderBy.col === item ? orderBy.mode : ''}>
                        <div className="headerText">
                          <p>{item}</p>
                        </div>
                      </th>
                    );
                  } else return '';
                })}
                {tableHeader.map((item, index) => {
                  if (item === idCol) return false;
                  if (item === 'IdShipping') return false;
                  if (fixedCols.indexOf(item) >= 0) return false;
                  if (allHeaders.indexOf(item) >= 0) {
                    return (
                      <th className={orderBy.col === item ? orderBy.mode : ''}>
                        <div className="headerText">
                          <p>{item}</p>
                        </div>
                      </th>
                    );
                  } else return '';
                })}
              </tr>
            </thead>
            <tbody>
              {tableData.length > 0 ? (
                tableData.map((row, index) => {
                  return renderTableRow(row, index);
                })
              ) : (
                <tr key="nodata">
                  <td colSpan={tableHeader.length + 1}>No Data Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
      <div className="pages">{renderPages()}</div>
    </div>
  );
};

export default FinanceTable;
