import React, { useEffect, useState, useRef, useContext, useCallback, Component } from 'react';
import Loader from 'react-dots-loader';
import 'semantic-ui-css/semantic.min.css';

import XLSX from "xlsx";

import { FileUploader } from "react-drag-drop-files";
import { Icon, Input } from 'semantic-ui-react';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import {
  Subtitle,
  Checkbox,
  Button,
  TextInput,
} from '../../../Components/Components';
import Table from '../../../Components/Table/Table';
import Utils from '../../../Components/utils';

import 'react-dots-loader/index.css';

import { ReactComponent as BoxIcon } from '../../../Components/images/icons/box.svg';
import { ReactComponent as ShippingIcon } from '../../../Components/images/icons/shipping.svg';
import { ReactComponent as FolderIcon } from '../../../Components/images/icons/folder.svg';
import { ReactComponent as DownloadIcon } from '../../../Components/images/icons/cloud-download.svg';
// import { ReactComponent as FinanceIcon } from '../../../Components/images/icons/financial-Icon-nw-2.svg';

import { UserContext } from '../../../Components/Contexts';

import './Schedule.scss';

const Schedule = () => {
  const { account, setAccount, isLogedIn } = useContext(UserContext);

  //Setar o valor total das linhas selecionadas.
  const [valorTotal, setTotalReceived] = useState([]);
  const [currencyValue, setCurrencyValue] = useState('');

  //guarda a qtd de shippings de cada status prepaid
  const [qtdSettledPrepaid, setQtdSettledPrepaid] = useState(0);
  const [qtdShippedPrepaid, setQtdShippedPrepaid] = useState(0);
  const [qtdConteinerPrepaid, setQtdConteinerPrepaid] = useState(0);
  const [qtdInTransitPrepaid, setQtdInTransitPrepaid] = useState(0);
  const [qtdLoadedPlantPrepaid, setQtdLoadedPlantPrepaid] = useState(0);
  const [qtdScheduledPrepaid, setQtdScheduledPrepaid] = useState(0);
  const [qtdUnderProPrepaid, setQtdUnderProPrepaid] = useState(0)
  const [qtdToProducedPrepaid, setQtdToProducedPrepaid] = useState(0);

  //guarda a qtd de shippings de cada status pending
  const [qtdSettledPending, setQtdSettledPending] = useState(0);
  const [qtdShippedPending, setQtdShippedPending] = useState(0);
  const [qtdConteinerPending, setQtdConteinerPending] = useState(0);
  const [qtdInTransitPending, setQtdInTransitPending] = useState(0);
  const [qtdLoadedPlantPending, setQtdLoadedPlantPending] = useState(0);
  const [qtdScheduledPending, setQtdScheduledPending] = useState(0);
  const [qtdUnderProPending, setQtdUnderProPending] = useState(0)
  const [qtdToProducedPending, setQtdToProducedPending] = useState(0);

  const [qtdSettledPPA, setQtdSettledPPA] = useState(0);
  const [qtdShippedPPA, setQtdShippedPPA] = useState(0);
  const [qtdConteinerPPA, setQtdConteinerPPA] = useState(0);
  const [qtdInTransitPPA, setQtdInTransitPPA] = useState(0);
  const [qtdLoadedPlantPPA, setQtdLoadedPlantPPA] = useState(0);
  const [qtdScheduledPPA, setQtdScheduledPPA] = useState(0);
  const [qtdUnderProPPA, setQtdUnderProPPA] = useState(0)
  const [qtdToProducedPPA, setQtdToProducedPPA] = useState(0);

  //guarda o estado do checkbox prepaid
  const [checkedSettledPrepaid, setCheckedSettledPrepaid] = useState(false);
  const [checkedShippedPrepaid, setCheckedShippedPrepaid] = useState(false);
  const [checkedConteinerPrepaid, setCheckedConteinerPrepaid] = useState(false);
  const [checkedInTransitPrepaid, setCheckedInTransitPrepaid] = useState(false);
  const [checkedLoadedPlantPrepaid, setCheckedLoadedPlantPrepaid] = useState(false);
  const [checkedScheduledPrepaid, setCheckedScheduledPrepaid] = useState(false);
  const [checkedUnderProPrepaid, setCheckedUnderProPrepaid] = useState(false);
  const [checkedToProducedPrepaid, setCheckedToProducedPrepaid] = useState(false);
  const [checkedAllPrepaid, setCheckedAllPrepaid] = useState(false);

  //guarda o estado do checkbox pending
  const [checkedSettledPending, setCheckedSettledPending] = useState(false);
  const [checkedShippedPending, setCheckedShippedPending] = useState(false);
  const [checkedConteinerPending, setCheckedConteinerPending] = useState(false);
  const [checkedInTransitPending, setCheckedInTransitPending] = useState(false);
  const [checkedLoadedPlantPending, setCheckedLoadedPlantPending] = useState(false);
  const [checkedScheduledPending, setCheckedScheduledPending] = useState(false);
  const [checkedUnderProPending, setCheckedUnderProPending] = useState(false);
  const [checkedToProducedPending, setCheckedToProducedPending] = useState(false);
  const [checkedAllPending, setCheckedAllPending] = useState(false);

  const [checkedSettledPPA, setCheckedSettledPPA] = useState(false);
  const [checkedShippedPPA, setCheckedShippedPPA] = useState(false);
  const [checkedConteinerPPA, setCheckedConteinerPPA] = useState(false);
  const [checkedInTransitPPA, setCheckedInTransitPPA] = useState(false);
  const [checkedLoadedPlantPPA, setCheckedLoadedPlantPPA] = useState(false);
  const [checkedScheduledPPA, setCheckedScheduledPPA] = useState(false);
  const [checkedUnderProPPA, setCheckedUnderProPPA] = useState(false);
  const [checkedToProducedPPA, setCheckedToProducedPPA] = useState(false);
  const [checkedAllPPA, setCheckedAllPPA] = useState(true);

  const statusField = 'Status';
  let status = useRef({
    Canceled: 0,
    Settled: 0,
    Shipped: 0,
    'Container at the Port': 0,
    'In Transit': 0,
    'Loaded on the Plant': 0,
    'Scheduled to be loaded': 0,
    'Under Production': 0,
    'To be Produced': 0,
  });

  const showData = useRef(false);
  const ovTable = useRef();

  const [fullTableData, setFullTableData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [tableFilters, setTableFilters] = useState([]);
  const [tableOverview, setTableOverview] = useState({});
  const [overviewFilters, setOverviewFilters] = useState([]);
  // Colocando de forma manual as colunas para a tabela
  // const [headerData, setHeaderData] = useState([
  //   'Agreed Date',
  //   'Settlement Date',
  //   'Status',
  //   'Stuffing Date',
  //   'Vehicle License Plate',
  //   'Prepayment Status',
  //   'Prepayment',
  //   'Prepayment Date',
  //   'Payment Received',
  //   'Vessel',
  //   'BL Consignee',
  // ]);

  const [headerData, setHeaderData] = useState([]);
  useEffect(() => {
    (async () => {
      let { columns } = await Utils.getCache('schedule');

      const defaultColumns = ['Purchase Order', 'Purchase Order Load', 'Prepayment Status', 'Status', 'Production Plant', 'Stuffing Date', 'Loading Date', 'Shipping Line', 'Vessel', 'Container', 'ETD', 'BL Date', 'Port of Destination'];

      setHeaderData(columns.length > 0 ? columns : defaultColumns);
    })();
  }, []);

  const [formItem, setFormItem] = useState({});
  const [formItemProducts, setFormItemProducts] = useState([]);
  const [formItemProductsLoader, setFormItemProductsLoader] = useState(true);
  const [formItemDocuments, setFormItemDocuments] = useState([]);
  const [formItemDocumentsLoader, setFormItemDocumentsLoader] = useState(true);
  const [formItemFinancial, setFormItemFinancial] = useState([]);
  const [formItemFinancialLoader, setFormItemFinancialLoader] = useState(true);
  const [formDisplay, setFormDisplay] = useState('shipping');
  const [tableStartPage, setTableStartPage] = useState(1);
  const [overviewLoading, setOverviewLoading] = useState(true);

  const [files, setFiles] = useState([]);
  const [arrayBase64, setArrayBase64] = useState([]);
  const [paymentVal, setPaymentVal] = useState();
  const [justificationVal, setJustificationVal] = useState('');
  const [arrayContractLoad, setArrayContractLoad] = useState([]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setOpen(false);
    setPaymentVal([]);
    setJustificationVal([]);
    setFiles([]);
    setArrayContractLoad([]);
    setArrayBase64([]);
  }

  const abaDePagamento = "Schedule";

  const fileTypes = ["JPG", "PNG", "GIF", "PDF", "TXT", "XLSX", "ZIP"];
  const fileNames = [];

  useEffect(() => {
    getTableData();
    setOverviewLoading(true);
  }, [account]);

  useEffect(() => { }, [tableData, formItem, tableOverview]);

  useEffect(() => {
    setFormItemProductsLoader(false);
    setFormItemDocumentsLoader(false);
    setFormItemFinancialLoader(false);
  }, [formItemProducts, formItemDocuments, formItemFinancial]);


  const getTableData = async () => {
    let settledPrepaid = 0;
    let shippedPrepaid = 0;
    let conteinerPrepaid = 0;
    let inTransitPrepaid = 0;
    let loadedPlantPrepaid = 0
    let scheduledPrepaid = 0;
    let underProductionPrepaid = 0;
    let toProducedPrepaid = 0;

    let settledPending = 0;
    let shippedPending = 0;
    let conteinerPending = 0;
    let inTransitPending = 0;
    let loadedPlantPending = 0
    let scheduledPending = 0;
    let underProductionPending = 0;
    let toProducedPending = 0;

    let settledPPA = 0
    let shippedPPA = 0;
    let conteinerPPA = 0;
    let inTransitPPA = 0;
    let loadedPlantPPA = 0
    let scheduledPPA = 0;
    let underProductionPPA = 0;
    let toProducedPPA = 0;

    const array = await Utils.getShippingData(account, { name: isLogedIn.name, email: JSON.parse(localStorage.getItem('logedInUser')).email });

    // console.log("array", array);

    const arrayComRepeticao = array;
  
    const propriedadeChave = 'IdShipping';
    
    const mapa = new Map();
    
    for (const item of arrayComRepeticao) {
        const chave = item[propriedadeChave];
    
        if (!mapa.has(chave)) {
            mapa.set(chave, item);
        }
    }

    // let arrayVal = Array.from(mapa.values()).map(objeto => {
    //   delete objeto.IdShipping;
    //   return objeto;
    // });    
    
    const arraySemRepeticao = Array.from(mapa.values());
  
  // console.log("arraySemRepeticao", arraySemRepeticao);
  

    for (let i = 0; i < arraySemRepeticao.length; i++) {
      if (arraySemRepeticao[i].FlagPrepaid == 1) { //verifica se shipping possui status pending
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'settled') {
          settledPrepaid += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'shipped') {
          shippedPrepaid += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'container at the port') {
          conteinerPrepaid += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'in transit') {
          inTransitPrepaid += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'loaded on the plant') {
          loadedPlantPrepaid += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'scheduled to be loaded') {
          scheduledPrepaid += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'under production') {
          underProductionPrepaid += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'to be produced') {
          toProducedPrepaid += 1;
        }
      } else if (arraySemRepeticao[i].FlagPending == 1) { //verifica se shipping possui status pending
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'settled') {
          settledPending += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'shipped') {
          shippedPending += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'container at the port') {
          conteinerPending += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'in transit') {
          inTransitPending += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'loaded on the plant') {
          loadedPlantPending += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'scheduled to be loaded') {
          scheduledPending += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'under production') {
          underProductionPending += 1;
        }
        if ((arraySemRepeticao[i].Status).toLowerCase() == 'to be produced') {
          toProducedPending += 1;
        }
      }
      // console.log("arraySemRepeticao[i].Status", arraySemRepeticao[i].Status);
      //verifica TOTAL
      if ((arraySemRepeticao[i].Status).toLowerCase() == 'settled') {
        settledPPA += 1;
      }
      if ((arraySemRepeticao[i].Status).toLowerCase() == 'shipped') {
        shippedPPA += 1;
      }
      if ((arraySemRepeticao[i].Status).toLowerCase() == 'container at the port') {
        conteinerPPA += 1;
      }
      if ((arraySemRepeticao[i].Status).toLowerCase() == 'in transit') {
        inTransitPPA += 1;
      }
      if ((arraySemRepeticao[i].Status).toLowerCase() == 'loaded on the plant') {
        loadedPlantPPA += 1;
      }
      if ((arraySemRepeticao[i].Status).toLowerCase() == 'scheduled to be loaded') {
        scheduledPPA += 1;
      }
      if ((arraySemRepeticao[i].Status).toLowerCase() == 'under production') {
        underProductionPPA += 1;
      }
      if (arraySemRepeticao[i].Status == 'To be Produced') {
        toProducedPPA += 1;
      }
    }

    setQtdSettledPrepaid(settledPrepaid);
    setQtdShippedPrepaid(shippedPrepaid);
    setQtdConteinerPrepaid(conteinerPrepaid);
    setQtdInTransitPrepaid(inTransitPrepaid);
    setQtdLoadedPlantPrepaid(loadedPlantPrepaid);
    setQtdScheduledPrepaid(scheduledPrepaid);
    setQtdUnderProPrepaid(underProductionPrepaid);
    setQtdToProducedPrepaid(toProducedPrepaid);

    setQtdSettledPending(settledPending);
    setQtdShippedPending(shippedPending);
    setQtdConteinerPending(conteinerPending);
    setQtdInTransitPending(inTransitPending);
    setQtdLoadedPlantPending(loadedPlantPending);
    setQtdScheduledPending(scheduledPending);
    setQtdUnderProPending(underProductionPending);
    setQtdToProducedPending(toProducedPending);

    setQtdSettledPPA(settledPPA);
    setQtdShippedPPA(shippedPPA);
    setQtdConteinerPPA(conteinerPPA);
    setQtdInTransitPPA(inTransitPPA);
    setQtdLoadedPlantPPA(loadedPlantPPA);
    setQtdScheduledPPA(scheduledPPA);
    setQtdUnderProPPA(underProductionPPA);
    setQtdToProducedPPA(toProducedPPA);



    setOverviewLoading(false);
    setFullTableData(arraySemRepeticao);
  };

  const handleChangeChecked = (e) => {
    let checkedSettledPrepaidAux = checkedSettledPrepaid;
    let checkedShippedPrepaidAux = checkedShippedPrepaid;
    let checkedConteinerPrepaidAux = checkedConteinerPrepaid;
    let checkedInTransitPrepaidAux = checkedInTransitPrepaid;
    let checkedLoadedPlantPrepaidAux = checkedLoadedPlantPrepaid;
    let checkedScheduledPrepaidAux = checkedScheduledPrepaid;
    let checkedUnderProPrepaidAux = checkedUnderProPrepaid;
    let checkedToProducedPrepaidAux = checkedToProducedPrepaid;
    let checkedAllPrepaidAux = checkedAllPrepaid;

    let checkedSettledPendingAux = checkedSettledPending;
    let checkedShippedPendingAux = checkedShippedPending;
    let checkedConteinerPendingAux = checkedConteinerPending;
    let checkedInTransitPendingAux = checkedInTransitPending;
    let checkedLoadedPlantPendingAux = checkedLoadedPlantPending;
    let checkedScheduledPendingAux = checkedScheduledPending;
    let checkedUnderProPendingAux = checkedUnderProPending;
    let checkedToProducedPendingAux = checkedToProducedPending;
    let checkedAllPendingAux = checkedAllPending;

    let checkedSettledPPAAux = checkedSettledPPA;
    let checkedShippedPPAAux = checkedShippedPPA;
    let checkedConteinerPPAAux = checkedConteinerPPA;
    let checkedInTransitPPAAux = checkedInTransitPPA;
    let checkedLoadedPlantPPAAux = checkedLoadedPlantPPA;
    let checkedScheduledPPAAux = checkedScheduledPPA;
    let checkedUnderProPPAAux = checkedUnderProPPA;
    let checkedToProducedPPAAux = checkedToProducedPPA;
    let checkedAllPPAAux = checkedAllPPA;

    switch (e.target.name) {
      case "checkedSettledPrepaid":
        setCheckedSettledPrepaid(e.target.checked);
        checkedSettledPrepaidAux = e.target.checked;
        break;

      case "checkedSettledPending":
        setCheckedSettledPending(e.target.checked);
        checkedSettledPendingAux = e.target.checked;
        break;
      case "checkedSettledPPA":
        setCheckedSettledPPA(e.target.checked);
        checkedSettledPPAAux = e.target.checked;
        break;

      case "checkedShippedPrepaid":
        setCheckedShippedPrepaid(e.target.checked);
        checkedShippedPrepaidAux = e.target.checked;
        break;

      case "checkedShippedPending":
        setCheckedShippedPending(e.target.checked);
        checkedShippedPendingAux = e.target.checked;
        break;

      case "checkedShippedPPA":
        setCheckedShippedPPA(e.target.checked);
        checkedShippedPPAAux = e.target.checked;
        break;

      case "checkedConteinerPrepaid":
        setCheckedConteinerPrepaid(e.target.checked);
        checkedConteinerPrepaidAux = e.target.checked;
        break;

      case "checkedConteinerPending":
        setCheckedConteinerPending(e.target.checked);
        checkedConteinerPendingAux = e.target.checked;
        break;

      case "checkedConteinerPPA":
        setCheckedConteinerPPA(e.target.checked);
        checkedConteinerPPAAux = e.target.checked;
        break;

      case "checkedInTransitPrepaid":
        setCheckedInTransitPrepaid(e.target.checked);
        checkedInTransitPrepaidAux = e.target.checked;
        break;

      case "checkedInTransitPending":
        setCheckedInTransitPending(e.target.checked);
        checkedInTransitPendingAux = e.target.checked;
        break;

      case "checkedInTransitPPA":
        setCheckedInTransitPPA(e.target.checked);
        checkedInTransitPPAAux = e.target.checked;
        break;

      case "checkedLoadedPlantPrepaid":
        setCheckedLoadedPlantPrepaid(e.target.checked);
        checkedLoadedPlantPrepaidAux = e.target.checked;
        break;

      case "checkedLoadedPlantPending":
        setCheckedLoadedPlantPending(e.target.checked);
        checkedLoadedPlantPendingAux = e.target.checked;
        break;

      case "checkedLoadedPlantPPA":
        setCheckedLoadedPlantPPA(e.target.checked);
        checkedLoadedPlantPPAAux = e.target.checked;
        break;

      case "checkedScheduledPrepaid":
        setCheckedScheduledPrepaid(e.target.checked);
        checkedScheduledPrepaidAux = e.target.checked;
        break;

      case "checkedScheduledPending":
        setCheckedScheduledPending(e.target.checked);
        checkedScheduledPendingAux = e.target.checked;
        break;

      case "checkedScheduledPPA":
        setCheckedScheduledPPA(e.target.checked);
        checkedScheduledPPAAux = e.target.checked;
        break;

      case "checkedUnderProPrepaid":
        setCheckedUnderProPrepaid(e.target.checked);
        checkedUnderProPrepaidAux = e.target.checked;
        break;

      case "checkedUnderProPending":
        setCheckedUnderProPending(e.target.checked);
        checkedUnderProPendingAux = e.target.checked;
        break;

      case "checkedUnderProPPA":
        setCheckedUnderProPPA(e.target.checked);
        checkedUnderProPPAAux = e.target.checked;
        break;

      case "checkedToProducedPrepaid":
        setCheckedToProducedPrepaid(e.target.checked);
        checkedToProducedPrepaidAux = e.target.checked;
        break;

      case "checkedToProducedPending":
        setCheckedToProducedPending(e.target.checked);
        checkedToProducedPendingAux = e.target.checked;
        break;

      case "checkedToProducedPPA":
        setCheckedToProducedPPA(e.target.checked);
        checkedToProducedPPAAux = e.target.checked;
        break;

      case "checkedAllPrepaid":
        setCheckedAllPrepaid(e.target.checked)
        checkedAllPrepaidAux = e.target.checked;
        break;

      case "checkedAllPending":
        setCheckedAllPending(e.target.checked)
        checkedAllPendingAux = e.target.checked;
        break;

      case "checkedAllPPA":
        setCheckedAllPPA(e.target.checked)
        checkedAllPPAAux = e.target.checked;
        break;

      default:
        break;
    }

    filterTable(
      checkedSettledPrepaidAux,
      checkedShippedPrepaidAux,
      checkedConteinerPrepaidAux,
      checkedInTransitPrepaidAux,
      checkedLoadedPlantPrepaidAux,
      checkedScheduledPrepaidAux,
      checkedUnderProPrepaidAux,
      checkedToProducedPrepaidAux,
      checkedAllPrepaidAux,
      checkedSettledPendingAux,
      checkedShippedPendingAux,
      checkedConteinerPendingAux,
      checkedInTransitPendingAux,
      checkedLoadedPlantPendingAux,
      checkedScheduledPendingAux,
      checkedUnderProPendingAux,
      checkedToProducedPendingAux,
      checkedAllPendingAux,
      checkedSettledPPAAux,
      checkedShippedPPAAux,
      checkedConteinerPPAAux,
      checkedInTransitPPAAux,
      checkedLoadedPlantPPAAux,
      checkedScheduledPPAAux,
      checkedUnderProPPAAux,
      checkedToProducedPPAAux,
      checkedAllPPAAux,
    );
  }

  const filterTable = async (
    checkedSettledPrepaid,
    checkedShippedPrepaid,
    checkedConteinerPrepaid,
    checkedInTransitPrepaid,
    checkedLoadedPlantPrepaid,
    checkedScheduledPrepaid,
    checkedUnderProPrepaid,
    checkedToProducedPrepaid,
    checkedAllPrepaid,
    checkedSettledPending,
    checkedShippedPending,
    checkedConteinerPending,
    checkedInTransitPending,
    checkedLoadedPlantPending,
    checkedScheduledPending,
    checkedUnderProPending,
    checkedToProducedPending,
    checkedAllPending,
    checkedSettledPPA,
    checkedShippedPPA,
    checkedConteinerPPA,
    checkedInTransitPPA,
    checkedLoadedPlantPPA,
    checkedScheduledPPA,
    checkedUnderProPPA,
    checkedToProducedPPA,
    checkedAllPPA
  ) => {
    let filteredData = [];

    if (checkedAllPrepaid || (
      checkedSettledPrepaid &&
      checkedShippedPrepaid &&
      checkedConteinerPrepaid &&
      checkedInTransitPrepaid &&
      checkedLoadedPlantPrepaid &&
      checkedScheduledPrepaid &&
      checkedUnderProPrepaid &&
      checkedToProducedPrepaid)) {

      filteredData = fullTableData.filter((item) => {
        return item.FlagPrepaid == 1
      })

    } else {

      if (checkedSettledPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'settled' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedShippedPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'shipped' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedConteinerPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'container at the port' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedInTransitPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'in transit' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedLoadedPlantPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'loaded on the plant' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedScheduledPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'scheduled to be loaded' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedUnderProPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'under production' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedToProducedPrepaid) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'to be produced' && item.FlagPrepaid == 1) {
            filteredData.push(item);
          }
        })
      }
    }

    if (checkedAllPending || (
      checkedSettledPending &&
      checkedShippedPending &&
      checkedConteinerPending &&
      checkedInTransitPending &&
      checkedLoadedPlantPending &&
      checkedScheduledPending &&
      checkedUnderProPending &&
      checkedToProducedPending)) {

      filteredData = fullTableData.filter((item) => {
        return item.FlagPending == 1
      })

    } else {

      if (checkedSettledPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'settled' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedShippedPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'shipped' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedConteinerPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'container at the port' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedInTransitPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'in transit' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedLoadedPlantPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'loaded on the plant' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedScheduledPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'scheduled to be loaded' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedUnderProPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'under production' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }

      if (checkedToProducedPending) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'to be produced' && item.FlagPending == 1) {
            filteredData.push(item);
          }
        })
      }
    }

    if (checkedAllPPA || (
      checkedSettledPPA &&
      checkedShippedPPA &&
      checkedConteinerPPA &&
      checkedInTransitPPA &&
      checkedLoadedPlantPPA &&
      checkedScheduledPPA &&
      checkedUnderProPPA &&
      checkedToProducedPPA)) {

      filteredData = fullTableData;
    } else {

      if (checkedSettledPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'settled') {
            filteredData.push(item);
          }
        })
      }

      if (checkedShippedPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'shipped') {
            filteredData.push(item);
          }
        })
      }

      if (checkedConteinerPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'container at the port') {
            filteredData.push(item);
          }
        })
      }

      if (checkedInTransitPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'in transit') {
            filteredData.push(item);
          }
        })
      }

      if (checkedLoadedPlantPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'loaded on the plant') {
            filteredData.push(item);
          }
        })
      }

      if (checkedScheduledPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'scheduled to be loaded') {
            filteredData.push(item);
          }
        })
      }

      if (checkedUnderProPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'under production') {
            filteredData.push(item);
          }
        })
      }

      if (checkedToProducedPPA) {
        fullTableData.filter((item) => {
          if ((item.Status).toLowerCase() == 'to be produced') {
            filteredData.push(item);
          }
        })
      }
    }

    filteredData.forEach((data, index) => {
      Object.keys(data).forEach((key) => {
        let aux = '';
        if (data[key]?.includes('$') && data[key]?.length > 1) {
          aux = parseInt(data[key].replace(/[$,]/g, '')).toLocaleString('pt-BR', { useGrouping: true, minimumFractionDigits: 3 });
          filteredData[index][key] = `$${aux}`;
        }
      });
    });

    await setTableData([]);
    await setFormItem({});
    await setTableData(filteredData);
    setTableStartPage(1);
  }

  const OverviewTip = (props) => {
    return (
      <div className="empty">
        <Subtitle text="Tutorial" />
        <h1>Choose a number in the table</h1>
        <h1>Follow the exemple bellow</h1>
        <img src="/mp4/friboi-tutorial.gif" alt="" />
      </div>
    );
  };

  const handleItemClick = async (item, page) => {
    await setFormDisplay('shipping');
    await setFormItem(item);
    await setTableStartPage(page);
    let pageTop = document.querySelector('.pageContent');
    // pageTop.scrollTop = 10000;
    document.getElementById('subtitleContainer').scrollIntoView();
  };

  const handleOverviewClick = async (e) => {
    let { prepayment, status } = e.dataset;
    let filters = [...overviewFilters];

    if (e.checked) {
      filters.push({ Status: status, Prepayment: prepayment });
    } else {
      filters.splice(
        filters.findIndex((item) => {
          return item.Status === status && item.Prepayment === prepayment;
        }),
        1,
      );
    }

    let filteredData = fullTableData.filter((item) => {
      let index = filters.findIndex((filter) => {
        return (
          (filter.Status === item[statusField] || filter.Status == 'all') &&
          (filter.Prepayment === item.Prepayment.toLowerCase() ||
            filter.Prepayment == 'all')
        );
      });
      if (index > -1) return true;
      return false;
    });

    await setOverviewFilters(filters);
    await setTableStartPage(1);
    await setTableData([]);
    await setFormItem({});
    await setTableData(filteredData);
  };

  const handleDataFilterBlockClose = async (status, prepayment) => {
    let item = document.querySelector(
      `div[data-status="${status}"][data-prepayment="${prepayment}"]`,
    );
    item.click();
  };

  const handleTableFilterBlockClose = async (item) => {
    let [[key, value]] = Object.entries(item);
    let data = [...tableData];
    // Comentei todos os getCache
    // let filters = await Utils.getCache('schedule');
    // filters.columnFilter = filters.columnFilter.filter(
    //   (filterItem) => filterItem[key] !== value,
    // );

    setTableFilters(tableFilters.filter((filter) => JSON.stringify(Object.entries(filter)[0]) !== JSON.stringify([key, value])));

    await setTableData([]);
    await setFormItem({});
    // await setTableFilters(filters.columnFilter);

    // Comentei todos os setCache
    // await Utils.setCache('schedule', filters);
    await setTableData(data);
  };

  const handleChangeFile = (fileUpload) => {
    setFiles([...files, fileUpload]);
  };

  const getBase64 = async (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.fileName = file.name;
      const nomeArquivo = reader.fileName;
      reader.onload = () => resolve(reader.result.substr(reader.result.indexOf(',') + 1));
      reader.onerror = (error) => reject(error);

      fileNames.push(nomeArquivo);
    });


  const handleFormMenuButton = async (evt, action) => {
    const menuItem = evt.currentTarget;
    const isSelected = menuItem.classList.contains('selected');
    if (isSelected) return false;

    document.querySelectorAll('.form > .menu div').forEach((item) => {
      item.classList.remove('selected');
    });
    setFormDisplay(action);
    menuItem.classList.add('selected');
    if (action === 'products') {
      setFormItemProductsLoader(true);
      let products = await Utils.getProducts(formItem.IdShipping);
      setFormItemProducts(products);
    }
    if (action === 'documents') {
      // console.log("DOCUMENTS");
      // console.log(formItem.IdShipping);
      setFormItemDocumentsLoader(true);
      let documents = await Utils.getDocuments(formItem.IdShipping);
      // console.log('await Utils.getDocuments(formItem.IdShipping): ', documents);
      setFormItemDocuments(documents);
    }
    if (action === 'financial') {
      setFormItemFinancialLoader(true);
      let financial = await Utils.getFinancial(formItem.Contract + '-' + formItem.Load);
      setFormItemFinancial(financial);
    }
  };

  const handleClickDownload = async (GUID, FileName) => {
    const { Conteudo } = await Utils.getDocumentBase64(GUID);

    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = 'data:application/octet-stream;base64,' + Conteudo;
    a.download = FileName;
    document.body.appendChild(a);
    a.click();
    alert('your file has downloaded!');
  };

  const stylebox = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };

  const removeFile = file => {
    const newFiles = [...files];
    newFiles.splice(file, 1);
    setFiles(newFiles);
  };

  const submitBase64Files = async (IdShipping, fileName, conteudo,
    justificationVal, paymentVal, email, valorTotal, abaDePagamento, clientName) => {

    let financialFile = await Utils.paymentFinancial(IdShipping, fileName, conteudo,
      justificationVal, paymentVal, email, valorTotal, abaDePagamento, clientName);

    return financialFile;
  };

  const calcColumnTotalProdutos = (column, format = true) => {
    let total = formItemProducts.reduce((total, atual) => total += atual[column], 0);

    if (format) total = total.toLocaleString('pt-BR', { useGrouping: true, minimumFractionDigits: 3 });
    return total;
  }

  const handleOnExport = () => {
    if (formItemProducts.length > 0) {
      const obj = {
        "Product": "",
        "Description": "TOTAL",
        "Number of Cartons": calcColumnTotalProdutos('Number of Cartons', false),
        "Net Weight": calcColumnTotalProdutos('Net Weight'),
        "Gross Weight": calcColumnTotalProdutos('Gross Weight'),
        "Quantity (KGs)": calcColumnTotalProdutos('Quantity (KGs)'),
        "Unit of Measurement": "KG",
        "Price": ""
      }
      
      formItemProducts.push(obj);
      // console.log("formItemProducts", formItemProducts);
  
      var wb = XLSX.utils.book_new(),
      ws = XLSX.utils.json_to_sheet(formItemProducts);
  
      XLSX.utils.book_append_sheet(wb, ws, "Shipping Products");
  
      XLSX.writeFile(wb, `Shipping Products - Contract&Load - ${formItem['Contract&Load']}.xlsx`);
    }
  }

  useEffect(() => {
    let checkedSettledPrepaidAux = checkedSettledPrepaid;
    let checkedShippedPrepaidAux = checkedShippedPrepaid;
    let checkedConteinerPrepaidAux = checkedConteinerPrepaid;
    let checkedInTransitPrepaidAux = checkedInTransitPrepaid;
    let checkedLoadedPlantPrepaidAux = checkedLoadedPlantPrepaid;
    let checkedScheduledPrepaidAux = checkedScheduledPrepaid;
    let checkedUnderProPrepaidAux = checkedUnderProPrepaid;
    let checkedToProducedPrepaidAux = checkedToProducedPrepaid;
    let checkedAllPrepaidAux = checkedAllPrepaid;

    let checkedSettledPendingAux = checkedSettledPending;
    let checkedShippedPendingAux = checkedShippedPending;
    let checkedConteinerPendingAux = checkedConteinerPending;
    let checkedInTransitPendingAux = checkedInTransitPending;
    let checkedLoadedPlantPendingAux = checkedLoadedPlantPending;
    let checkedScheduledPendingAux = checkedScheduledPending;
    let checkedUnderProPendingAux = checkedUnderProPending;
    let checkedToProducedPendingAux = checkedToProducedPending;
    let checkedAllPendingAux = checkedAllPending;

    let checkedSettledPPAAux = checkedSettledPPA;
    let checkedShippedPPAAux = checkedShippedPPA;
    let checkedConteinerPPAAux = checkedConteinerPPA;
    let checkedInTransitPPAAux = checkedInTransitPPA;
    let checkedLoadedPlantPPAAux = checkedLoadedPlantPPA;
    let checkedScheduledPPAAux = checkedScheduledPPA;
    let checkedUnderProPPAAux = checkedUnderProPPA;
    let checkedToProducedPPAAux = checkedToProducedPPA;
    let checkedAllPPAAux = checkedAllPPA;

    filterTable(
      checkedSettledPrepaidAux,
      checkedShippedPrepaidAux,
      checkedConteinerPrepaidAux,
      checkedInTransitPrepaidAux,
      checkedLoadedPlantPrepaidAux,
      checkedScheduledPrepaidAux,
      checkedUnderProPrepaidAux,
      checkedToProducedPrepaidAux,
      checkedAllPrepaidAux,
      checkedSettledPendingAux,
      checkedShippedPendingAux,
      checkedConteinerPendingAux,
      checkedInTransitPendingAux,
      checkedLoadedPlantPendingAux,
      checkedScheduledPendingAux,
      checkedUnderProPendingAux,
      checkedToProducedPendingAux,
      checkedAllPendingAux,
      checkedSettledPPAAux,
      checkedShippedPPAAux,
      checkedConteinerPPAAux,
      checkedInTransitPPAAux,
      checkedLoadedPlantPPAAux,
      checkedScheduledPPAAux,
      checkedUnderProPPAAux,
      checkedToProducedPPAAux,
      checkedAllPPAAux,
    );
  }, [fullTableData]);

  const Form = () => {
    return (
      <div className="form">
        <div className="menu noselect">
          <div
            className="backButton button"
            onClick={() => {
              setFormItem({});
              localStorage.getItem("itemPorPagina");
            }}
          >
            <img src="/images/icons/arrow-left-outline-icon_blue.svg" alt="" />
            <p>Back</p>
          </div>
          <div
            className="ProductsButton button selected"
            onClick={(e) => {
              handleFormMenuButton(e, 'shipping');
            }}
          >
            <ShippingIcon />
            <p>Shipping</p>
          </div>
          <div
            className="ProductsButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'products');
            }}
          >
            <BoxIcon />
            <p>Products</p>
          </div>
          <div
            className="ProductsButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'documents');
            }}
          >
            <FolderIcon />
            <p>Documents</p>
          </div>
          <div
            className="FinancialButton button"
            onClick={(e) => {
              handleFormMenuButton(e, 'financial');
            }}>
            <FolderIcon />
            <p>Financial</p>
          </div>
        </div>
        <div className="formContent">
          <div className="title">
            {formDisplay === 'products' && formItemProducts.length > 0 && (
              <><h1>Contract&Load: {formItem['Contract&Load']}</h1><button onClick={handleOnExport} style={{cursor: "pointer", marginRight: '10px', padding: '3px', backgroundColor: '#fff', border: 'none', borderRadius: '2px'}}>Download</button></>
            )}
            {formDisplay !== 'products' && (
              <h1>Contract&Load: {formItem['Contract&Load']}</h1>
            )}
          </div>
          {formDisplay === 'shipping' && (
            <ul>
              {Object.keys(formItem).map((col, index) => {
                if (col == 'Contract&Load') return false;
                if (col == 'IdShipping') return false;
                return (
                  <li key={index}>
                    <p className="label">{col}:</p>{' '}
                    <p className="value">
                      {Utils.outputData(formItem[col], col)}
                    </p>
                  </li>
                );
              })}
            </ul>
          )}
          {formDisplay === 'products' && (
            <div className="contentProducts">
              {formItemProductsLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <table>
                  <thead style={{ border: 0 }}>
                    <tr>
                      {formItemProducts.length > 0 ? Object.keys(formItemProducts[0]).map((item) => (
                        <th>{item}</th>
                      )) :
                        <ul>
                          <li>
                            <p className="label" style={{ justifyContent: 'center' }}>
                              Shipping does not have products to show
                            </p>
                          </li>
                        </ul>
                      }
                    </tr>
                  </thead>
                  <tbody>
                    {formItemProducts.map((row) => {
                      return (
                        <tr>
                          {Object.values(row).map((item) => (
                            <td>{item}</td>
                          ))}
                        </tr>
                      );
                    })}
                    {formItemProducts.length > 0 && <tr>
                      <td></td> {/* Product */}
                      <td><b>TOTAL</b></td> {/* Description */}
                      <td><b>{calcColumnTotalProdutos('Number of Cartons', false)}</b></td>
                      <td><b>{calcColumnTotalProdutos('Net Weight')}</b></td>
                      <td><b>{calcColumnTotalProdutos('Gross Weight')}</b></td>
                      <td><b>{calcColumnTotalProdutos('Quantity (KGs)')}</b></td>
                      <td><b>KG</b></td> {/* Unit of Measurement */}
                      <td></td> {/* Price */}
                    </tr>}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {formDisplay === 'documents' && (
            <div className="contentDocuments">
              {formItemDocumentsLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <table>
                  <thead>
                    <tr>
                      <th>Category</th>
                      <th>Document</th>
                      <th>Upload Date</th>
                      <th> - </th>
                    </tr>
                  </thead>
                  <tbody>
                    {formItemDocuments[0].FileName != "" ? (
                      formItemDocuments.map((document, index) => {
                        // console.log(document);
                        return (
                          <tr key={index + document.FileName + document.Categoria}>
                            <td><p className="label">{document.Categoria}</p></td>
                            <td><p className="value">{document.FileName}</p></td>
                            <td><p className="value">{document.UpdateDate_c}</p></td>
                            <td>
                              <Button
                                style={{
                                  width: 'auto',
                                  height: 'auto',
                                  padding: '7px 7px 5px 5px',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                }}
                                type="red"
                                caption="Download"
                                onClick={() => {
                                  handleClickDownload(
                                    document.GUID,
                                    document.FileName,
                                  );
                                }}
                                shape="square"
                              >
                                <DownloadIcon
                                  width={20}
                                  style={{
                                    marginRight: '5px',
                                    marginBottom: '2px',
                                  }}
                                />
                              </Button>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <li>
                        <p className="label" style={{ justifyContent: 'center' }}>
                          Shipping does not have documents to show
                        </p>
                      </li>
                    )}
                  </tbody>
                </table>
              )}
            </div>
          )}
          {formDisplay === 'financial' && (
            <div className="contentFinancial">
              {formItemFinancialLoader ? (
                <div className="loader">
                  <Loader color="#E30613" />
                </div>
              ) : (
                <>
                  <Button
                    style={{
                      marginTop: '10px',
                      marginBottom: '10px',
                      width: '220px',
                      height: 'auto',
                      fontFamily: 'Roboto',
                      marginLeft: '39%'
                    }}
                    type="red"
                    caption="Upload Payment File"
                    shape="square" handleCh
                    onClick={handleOpen}>
                  </Button>
                  <div>
                    <Modal
                      open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                      <Box sx={stylebox}>
                        <div>
                          <div>
                            <div style={{ textAlign: 'center', fontFamily: 'Roboto', color: 'rgba(13, 23, 79, 1)' }}>
                              <h1>UPLOAD PAYMENT VALUES</h1>
                              <h2>On this screen, upload the files referring to the selected Shippings</h2>
                            </div>
                          </div>
                        </div>
                        <div className="dropzone">
                          <FileUploader handleChange={handleChangeFile} name="file" types={fileTypes} />
                          <div style={{ marginTop: '5px' }}>
                            {files.length ? (
                              files.map((item, index) =>
                                <>
                                  <p key={index}
                                    style={{
                                      fontFamily: 'Roboto',
                                      fontWeight: 700, // Roboto bold
                                      justifyContent: 'space-between',
                                    }}>File name: {item.name}
                                    <button
                                      style={{
                                        float: 'right'
                                      }}
                                      onClick={() => removeFile(index)}
                                    >
                                      <Icon name='trash alternate' />
                                    </button>
                                  </p>

                                </>
                              )
                            ) : (
                              <p style={{ fontFamily: 'Roboto', fontWeight: 700 /* Roboto bold */, color: 'red' }}>No files uploaded yet</p>
                            )}
                          </div>
                        </div>
                        <div style={{ fontFamily: 'Roboto', fontWeight: 700 /* Roboto bold */ }}>
                          <p>Total:</p>
                        </div>
                        <div className="inputLabelFinancial">
                          <div className="paymentValue">
                            <div className="titlesInput">
                              <label>
                                Payment value:
                              </label>
                            </div>
                            <div className="inputFinancial">
                              <Input
                                type="number"
                                icon='dollar sign'
                                iconPosition='left'
                                placeholder='500,00'
                                value={paymentVal}
                                onInput={e => setPaymentVal(e.target.value)}
                              />
                            </div>
                          </div>
                          <div className="justification">
                            <div className="titlesInput">
                              <label>
                                Justification
                              </label>
                            </div>
                            <div className="inputFinancial">
                              <Input
                                placeholder='This payment...'
                                style={{ width: '370px' }}
                                value={justificationVal}
                                onInput={e => setJustificationVal(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="submitButton">
                          <Button
                            style={{
                              width: '120px',
                              height: 'auto',
                            }}
                            type="red"
                            caption="Submit"
                            shape="square"
                            onClick={async () => {
                              if (files.length >= 1 && justificationVal.length > 0 || paymentVal.length > 0) {
                                const { email } = await JSON.parse(localStorage.getItem('logedInUser'));
                                const clientName = localStorage.getItem('nameClient');

                                for (let i = 0; i < files.length; i++) {
                                  const resultado = await getBase64(files[i]);
                                  arrayBase64.push(resultado);
                                  submitBase64Files(formItem.IdShipping,
                                    fileNames[i], resultado,
                                    justificationVal, paymentVal, email, valorTotal,
                                    abaDePagamento, clientName);
                                }
                                alert('Send was sucessful! Your files will be sent by email to our financial sector');
                              } else {
                                alert('You must write a justification');
                              };
                              handleClose();
                            }}>
                          </Button>
                        </div>
                      </Box>
                    </Modal>
                  </div>
                  <ul>
                    {formItemFinancial &&
                      formItemFinancial.length >= 1 ? (
                      formItemFinancial.map((document, index) => {
                        return (
                          <li key={index}>
                            <p>Justification: {document.Justification_c}</p>
                            <p>Payment value: {document.PaymentValue_c}</p>
                          </li>
                        );
                      })
                    ) : (
                      <li>
                        <p className="label" style={{ justifyContent: 'center' }}>
                          Shipping does not have financial files
                        </p>
                      </li>
                    )}
                  </ul>
                </>
              )}
            </div>
          )}
        </div>
      </div >
    );
  };

  return (
    <div
      className="scheduleContent"
      tabIndex="0"
      onKeyUp={(e) => {
        if (e.which === 27) {
          setFormItem({});
        }
      }}
    >
      <div className="overview">
        {overviewLoading ? (
          <div className="loader">
            <Loader color="#E30613" />
          </div>
        ) : (
          <div className="overviewContent">
            <div className='filter'>
              <table style={{ marginRight: '100px' }} ref={ovTable}>
                <thead>
                  <tr>
                    <th>STATUS</th>
                    <th>TOTAL</th>
                    <th>PREPAID</th>
                    <th>PENDING</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Settled</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedSettledPPA}
                        name="checkedSettledPPA"
                        defaultChecked={checkedSettledPPA}
                        onChange={handleChangeChecked}
                      />{qtdSettledPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedSettledPrepaid}
                        name="checkedSettledPrepaid"
                        defaultChecked={checkedSettledPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdSettledPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedSettledPending}
                        name="checkedSettledPending"
                        defaultChecked={checkedSettledPending}
                        onChange={handleChangeChecked}
                      />{qtdSettledPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Shipped</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedShippedPPA}
                        name="checkedShippedPPA"
                        defaultChecked={checkedShippedPPA}
                        onChange={handleChangeChecked}
                      />{qtdShippedPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedShippedPrepaid}
                        name="checkedShippedPrepaid"
                        defaultChecked={checkedShippedPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdShippedPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedShippedPending}
                        name="checkedShippedPending"
                        defaultChecked={checkedShippedPending}
                        onChange={handleChangeChecked}
                      />{qtdShippedPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Container at the Port</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedConteinerPPA}
                        name="checkedConteinerPPA"
                        defaultChecked={checkedConteinerPPA}
                        onChange={handleChangeChecked}
                      />{qtdConteinerPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedConteinerPrepaid}
                        name="checkedConteinerPrepaid"
                        defaultChecked={checkedConteinerPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdConteinerPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedConteinerPending}
                        name="checkedConteinerPending"
                        defaultChecked={checkedConteinerPending}
                        onChange={handleChangeChecked}
                      />{qtdConteinerPending}
                    </td>
                  </tr>
                  <tr>
                    <td>In Transit</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedInTransitPPA}
                        name="checkedInTransitPPA"
                        defaultChecked={checkedInTransitPPA}
                        onChange={handleChangeChecked}
                      />{qtdInTransitPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedInTransitPrepaid}
                        name="checkedInTransitPrepaid"
                        defaultChecked={checkedInTransitPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdInTransitPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedInTransitPending}
                        name="checkedInTransitPending"
                        defaultChecked={checkedInTransitPending}
                        onChange={handleChangeChecked}
                      />{qtdInTransitPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Loaded on the Plant</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedLoadedPlantPPA}
                        name="checkedLoadedPlantPPA"
                        defaultChecked={checkedLoadedPlantPPA}
                        onChange={handleChangeChecked}
                      />{qtdLoadedPlantPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedLoadedPlantPrepaid}
                        name="checkedLoadedPlantPrepaid"
                        defaultChecked={checkedLoadedPlantPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdLoadedPlantPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedLoadedPlantPending}
                        name="checkedLoadedPlantPending"
                        defaultChecked={checkedLoadedPlantPending}
                        onChange={handleChangeChecked}
                      />{qtdLoadedPlantPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Scheduled to be Loaded</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedScheduledPPA}
                        name="checkedScheduledPPA"
                        defaultChecked={checkedScheduledPPA}
                        onChange={handleChangeChecked}
                      />{qtdScheduledPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedScheduledPrepaid}
                        name="checkedScheduledPrepaid"
                        defaultChecked={checkedScheduledPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdScheduledPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedScheduledPending}
                        name="checkedScheduledPending"
                        defaultChecked={checkedScheduledPending}
                        onChange={handleChangeChecked}
                      />{qtdScheduledPending}
                    </td>
                  </tr>
                  <tr>
                    <td>Under Production</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedUnderProPPA}
                        name="checkedUnderProPPA"
                        defaultChecked={checkedUnderProPPA}
                        onChange={handleChangeChecked}
                      />{qtdUnderProPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedUnderProPrepaid}
                        name="checkedUnderProPrepaid"
                        defaultChecked={checkedUnderProPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdUnderProPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedUnderProPending}
                        name="checkedUnderProPending"
                        defaultChecked={checkedUnderProPending}
                        onChange={handleChangeChecked}
                      />{qtdUnderProPending}
                    </td>
                  </tr>
                  <tr>
                    <td>To be Produced</td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedToProducedPPA}
                        name="checkedToProducedPPA"
                        defaultChecked={checkedToProducedPPA}
                        onChange={handleChangeChecked}
                      />{qtdToProducedPPA}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedToProducedPrepaid}
                        name="checkedToProducedPrepaid"
                        defaultChecked={checkedToProducedPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdToProducedPrepaid}
                    </td>
                    <td className="checkboxBalance">
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedToProducedPending}
                        name="checkedToProducedPending"
                        defaultChecked={checkedToProducedPending}
                        onChange={handleChangeChecked}
                      />{qtdToProducedPending}
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th> ALL</th>
                    <th>
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedAllPPA}
                        name="checkedAllPPA"
                        defaultChecked={checkedAllPPA}
                        onChange={handleChangeChecked}
                      />{qtdSettledPPA + qtdShippedPPA + qtdConteinerPPA + qtdInTransitPPA
                        + qtdLoadedPlantPPA + qtdScheduledPPA + qtdUnderProPPA + qtdToProducedPPA}
                    </th>
                    <th>
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedAllPrepaid}
                        name="checkedAllPrepaid"
                        defaultChecked={checkedAllPrepaid}
                        onChange={handleChangeChecked}
                      />{qtdSettledPrepaid + qtdShippedPrepaid + qtdConteinerPrepaid + qtdInTransitPrepaid
                        + qtdLoadedPlantPrepaid + qtdScheduledPrepaid + qtdUnderProPrepaid + qtdToProducedPrepaid}
                    </th>
                    <th>
                      <input
                        type="checkbox"
                        style={{ marginRight: '5px' }}
                        checked={checkedAllPending}
                        name="checkedAllPending"
                        defaultChecked={checkedAllPending}
                        onChange={handleChangeChecked}
                      />{qtdSettledPending + qtdShippedPending + qtdConteinerPending + qtdInTransitPending
                        + qtdLoadedPlantPending + qtdScheduledPending + qtdUnderProPending + qtdToProducedPending}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        )}
      </div>
      {tableData.length > 0 ? (
        <div className="data">
          {Object.keys(overviewFilters).length > 0 && (
            <div className="dataFilters">
              {Object.keys(overviewFilters).map((item) => {
                return (
                  <div className="dataFilterBlock">
                    <div
                      className="close"
                      onClick={() => {
                        handleDataFilterBlockClose(
                          overviewFilters[item].Status,
                          overviewFilters[item].Prepayment,
                        );
                      }}
                    >
                      <img
                        className="cancelFilter"
                        src="/images/icons/plus-outline-icon.svg"
                        alt="Remove Filter"
                        title="Remove Filter"
                      />
                    </div>
                    <p>
                      <span className="dataFilterTitle">Status</span>:
                      <span className="dataFilterText">
                        {overviewFilters[item].Status}
                      </span>
                    </p>
                    <p>
                      <span className="dataFilterTitle">Prepayment</span>:
                      <span className="dataFilterText">
                        {overviewFilters[item].Prepayment}
                      </span>
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {tableFilters.length > 0 && (
            <div className="dataFilters">
              {tableFilters.map((item) => {
                let [[key, value]] = Object.entries(item);
                return (
                  <div className="dataFilterBlock">
                    <div
                      className="close"
                      onClick={() => {
                        handleTableFilterBlockClose(item);
                      }}
                    >
                      <img
                        className="cancelFilter"
                        src="/images/icons/plus-outline-icon.svg"
                        alt="Remove Filter"
                        title="Remove Filter"
                      />
                    </div>
                    <p>
                      <span className="dataFilterTitle">{key}</span>:
                      <span className="dataFilterText">{value}</span>
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          <Subtitle text="Data" />

          {Object.keys(formItem).length > 0 ? (
            Form()
          ) : (
            <Table
              data={tableData}
              header={headerData}
              startPage={tableStartPage || 1}
              itemClick={handleItemClick}
              idCol="Contract&Load"
              setTableFilters={setTableFilters}
              setTotalReceived={setTotalReceived}
              cache="schedule"
            />
          )}
        </div>
      ) : (
        <div className="data">
          <Subtitle text="Data" />
          <Table
            data={[]}
            header={headerData}
            startPage={tableStartPage || 1}
            itemClick={handleItemClick}
            idCol="Contract&Load"
            cache="schedule"
          />
        </div>
      )}
    </div>
  );
};

export default Schedule;
